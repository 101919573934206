import { getRefreshTokenAPI, getCartonsAPI, exportExcelAPI, getDataByFieldForCartonAPI } from "../../services"

export const getCartons = async (setCartons, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sortStr, query, project) => {
  try {
    setLoading(true)
    const {data} = await getCartonsAPI(token, limit, offset, sortStr, query, project)
    setCartons(data.data)
    setTotal(data.total_count)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getDataByField = async (setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters, project) => {
  try {
    setLoadingDataByField(true)
    const {data} = await getDataByFieldForCartonAPI(token, project)
    filters.map((filter) => {
      setDataByField(prev => {
        return {
          ...prev, 
          [filter.label]: ((data || {})[filter.field] || []).map((d, idx) => {
            return {
              label: d,
              value: d
            }
          })
        }
      })
    })
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const exportExcel = async (token, setToken, query, setLoading, setError, openNotification, from, project) => {
  try {
    setLoading(true)
    // const {data, headers} = await exportExcelAPI(token, query)
    const response = await exportExcelAPI(token, query, from, project)
    let url = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    const disposition = response.headers.get('Content-Disposition');
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    if (filename.toLowerCase().startsWith("utf-8''"))
      filename = decodeURIComponent(filename.replace("utf-8''", ''));
    else
      filename = filename.replace(/['"]/g, '');
    link.setAttribute(
      'download',
      `${filename}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setError('')
  } catch (error) {
    console.log("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}