import { SERVER_API_URL } from "../constants"
import axios from "axios"

export const getSnapShotsAPI = (token, limit, offset, sortStr, query, project) => {
  let requestBody = {}
  for (const property in query) {
    if (property === 'name') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: query[property]
        }
      }
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: (query[property]).join('\\')
        }
      }
    }
  }

  return axios.post(`${SERVER_API_URL}/snapshot?limit=${limit}&skip=${offset}${!!sortStr ? `&sort=${sortStr}` : ''}&project=${project}`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const createSnapshotAPI = (name, project, token) => {
  let requestBody = {name:name, project:project}

  return axios.post(`${SERVER_API_URL}/snapshot/create`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const getSnapShotsDetailsAPI = (token, limit, offset, sortStr, query, moreQuery) => {
  let requestBody = {}
  for (const property in query) {
    if (property === 'carton') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: query[property]
        }
      }
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: (query[property]).join('\\')
        }
      }
    }
  }

  return axios.post(`${SERVER_API_URL}/snapshot/details?limit=${limit}&skip=${offset}${!!sortStr ? `&sort=${sortStr}` : ''}&${moreQuery}`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const getDataByFieldForSnapShotDetailsAPI = (token, moreQuery) => {
  return axios.get(SERVER_API_URL + "/snapshot/field/details?" + moreQuery, {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const getDataByFieldForSnapShotAPI = (token, project) => {
  return axios.get(SERVER_API_URL + "/snapshot/field?project=" + project, {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}