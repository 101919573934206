import { useState, useEffect, useMemo, createContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Table, Spin, notification, Row, Col, Select, Input, Button, Badge, Space, Typography, Tabs, Card, Statistic } from "antd"
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { getPOs, getPO, getVendorsData, exportExcel, getDataByField, getStatics, getNotes } from './utils'
const { Text } = Typography;
const { TextArea } = Input

const Context = createContext({
  status: 'status',
  errorMsg: 'error'
})
const filters = ['vendor', 'vendor_grade', 'manufacturer', 'model', 'variant', 'network', 'capacity']
const Po = ({ token, setToken }) => {
  const [pos, setPos] = useState([])
  const [po, setPO] = useState([])
  const [showColumnsForPO, setShowColumnsForPO] = useState([])
  const [columnsForPO, setColumnsForPO] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({})
  const [dataByField, setDataByField] = useState({})
  const [loadingDataByField, setLoadingDataByField] = useState(false)
  const [loadingStatics, setLoadingStatics] = useState(false)
  const [statics, setStatics] = useState({})
  const [error, setError] = useState({})
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [limitForPo, setLimitForPo] = useState(10)
  const [offsetForPo, setOffsetForPo] = useState(0)
  const [sort, setSort] = useState()
  const [sortForPo, setSortForPo] = useState()
  const [api, contextHolder] = notification.useNotification()
  const [ponum, setPonum] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [notes, setNotes] = useState([])
  const [loadingNotes, setLoadingNotes] = useState(false)

  let location = useLocation()
  const openNotification = placement => {
    api.error({
      message: <Context.Consumer>{({ status }) => status}</Context.Consumer>,
      description: <Context.Consumer>{({ errorMsg }) => `${errorMsg}!`}</Context.Consumer>,
      placement
    })
  }

  const contextValue = useMemo(() => !!error && ({
    status: error.status,
    errorMsg: error.statusText
  }), [error])

  useEffect(() => {
    if (!!window.location.href.split('/')[4]) {
      getDataByField(setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters, window.location.href.split('/')[4])
      getStatics(setStatics, setLoadingStatics, setError, openNotification, setLoading, token, setToken, window.location.href.split('/')[4])
      getNotes(openNotification, token, setToken, setNotes, setLoadingNotes, setLoading, setError, window.location.href.split('/')[4])
    } else {
      getVendorsData(setDataByField, setLoadingDataByField, setLoading, setError, setToken, openNotification, token)
    }
  }, [token, ponum, location])

  useEffect(() => {
    if (!!window.location.href.split('/')[4]) {
      if (!ponum) setPonum(true)
      getPO(setPO, setLoading, setTotal, setError, setToken, openNotification, token, window.location.href.split('/')[4], limitForPo, offsetForPo, sortForPo, query, columnsForPO)
    } else {
      setPO(null)
      setPonum(false)
      getPOs(setPos, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query)
    }
  }, [query, ponum, token, sortForPo, sort, offset, limit, offsetForPo, limitForPo, columnsForPO, location])

  const handleChange = (pagination, filters, sorter) => {
    const offset1 = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit1 = pagination.pageSize;
    setOffset(offset1)
    setLimit(limit1)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "%2B" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "%2B" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSort(sortStr)
  };

  const handleChangeForPO = (pagination, filters, sorter) => {
    const offset1 = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit1 = pagination.pageSize;
    setOffsetForPo(offset1)
    setLimitForPo(limit1)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "%2B" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "%2B" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSortForPo(sortStr)
  };


  const columns = [
    {
      title: 'Number',
      dataIndex: 'po_number',
      key: 'number',
      render: text => <Link to={`/po/${text}`} onClick={() => setPonum(true)}>{text}</Link>,
      sorter: {
        multiple: 2
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      sorter: {
        multiple: 1
      },
    },
    {
      title: 'Date',
      dataIndex: 'purchase_date',
      key: 'date',
      sorter: {
        multiple: 3
      },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      sorter: {
        multiple: 4
      },
    },
  ]

  useEffect(() => {
    let col = []
    if (po?.length > 0) {
      col = Object.keys(po[0]).map((d, idx) => {
        if (d === "imei") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sorter: {
              multiple: 1,
            },
            render: text => <Link to={`/imei/${text}`}>{text}</Link>,
          }
        } else {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sorter: {
              multiple: 1,
            },
          }
        }
      })
      if (columnsForPO.filter(cl => cl.key !== "count").length === 0) {
        setShowColumnsForPO(col)
        setColumnsForPO(col)
      }
    }
  }, [po])


  const items = [
    {
      key: '1',
      label: 'Orders',
      children:
        <>
          <Row gutter={[8, 8]}>
            <Col span={15}>
              <Row gutter={[16, 16]}>
                {
                  filters.map(filter =>
                    <Col span={6}>
                      <Select
                        mode="multiple"
                        className="w-full"
                        showSearch
                        allowClear
                        options={dataByField[filter] || []}
                        placeholder={filter.charAt(0).toUpperCase() + filter.slice(1)}
                        notFoundContent={loadingDataByField ? <Spin size="small" /> : null}
                        onChange={(value) => setQuery({ ...query, [filter]: value })}
                      />
                    </Col>
                  )
                }
                <Col span={24}>
                  <Select
                    mode="multiple"
                    className="w-full"
                    allowClear
                    options={showColumnsForPO.map(d => {
                      return {
                        label: d.key,
                        value: d.key
                      }
                    })}
                    placeholder="Columns"
                    notFoundContent={showColumnsForPO ? <Spin size="small" /> : null}
                    onChange={(value) => {
                      setColumnsForPO([...(showColumnsForPO.filter(d => value.includes(d.key))), ...((showColumnsForPO.filter(d => value.includes(d.key))).map(cl => cl.key).includes('imei') ? [] : [{
                        title: 'count',
                        dataIndex: 'count',
                        key: 'count',
                        sorter: {
                          multiple: 1000,
                        }
                      }])])
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <TextArea
                className="w-full"
                placeholder="IMEI"
                onChange={(e) => setQuery({ ...query, imei: e.target.value.replaceAll('\n', ' ').replaceAll(' ', '\\') })}
                rows={3}
              />
            </Col>
            <Col span={3} className="utils">
              <Button type="primary" disabled={loadingExport} icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />} onClick={() => exportExcel(token, setToken, query, setLoadingExport, setError, openNotification, 'po', window.location.href.split('/')[4], columnsForPO)}>
                Export
              </Button>
              <Badge status="success" text={`Qty: ${total}`} className="total" />
            </Col>
          </Row>
          <Table
            columns={columnsForPO}
            dataSource={po}
            onChange={handleChangeForPO}
            scroll={{ x: 'max-content' }}
            rowKey="key"
            pagination={{ total: total }}
          />
        </>
    },
    {
      key: '2',
      label: 'Financial Analysis',
      children: <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Paid Value"
              value={loadingStatics ? 'loading...' : !!statics.total_value ? !!statics.total_value[0] ? statics.total_value[0].sum.substr(1,) : 0 : 0}
              precision={2}
              prefix="$"
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Revenue from PO"
              value={0}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix="$"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Booked Profit"
              value={0}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix="$"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Cashflow Impact"
              value={0}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix="$"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Remaining Cost Basis"
              value={0}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix="$"
            />
          </Card>
        </Col>
      </Row>
    },
    {
      key: '3',
      label: 'Processing Analysis',
      children: <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card>
            <Statistic
              title="PO Total Count"
              value={loadingStatics ? 'loading...' : !!statics.total_count ? statics.total_count[0].count : 0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Wiped Count"
              value={loadingStatics ? 'loading...' : !!statics.total_count ? statics.wiped_count[0].count : 0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Tested Count"
              value={loadingStatics ? 'loading...' : !!statics.total_count ? statics.tested_count[0].count : 0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Repaired Count (Failed)"
              value={loadingStatics ? 'loading...' : !!statics.total_count ? statics.repaired_count_failed[0].count : 0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Repaired Count (Successful)"
              value={loadingStatics ? 'loading...' : !!statics.total_count ? statics.repaired_count_success[0].count : 0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
      </Row>
    },
    {
      key: '4',
      label: <Badge count={loadingNotes ? <Spin size="small"></Spin> : notes.length} offset={[15, 0]}><span>Notes</span></Badge>,
      children: <Spin spinning={loadingNotes} tip="Loading..." size="large">
        <Space direction="vertical">
          <Card title="Notes" style={{ width: '300px' }}>
            <Space size="middle" direction="vertical">
              {(() => {
                const groups = notes.map(note => {
                  let temp = new Date(note.added).toLocaleString('en-US').split(', ')
                  return {
                    ...note,
                    date: temp[0],
                    time: temp[1]
                  }
                }).reduce((group, product) => {
                  const { date } = product;
                  group[date] = group[date] ?? [];
                  group[date].push(product);
                  return group;
                }, {})
                return Object.keys(groups).map((date, idx) =>
                  <Space size="small" direction="vertical">
                    <Text><b>{date}</b></Text>
                    {
                      groups[date].map(data =>
                        <Space size="middle" style={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Text style={{ whiteSpace: 'nowrap' }}>{data.time}</Text>
                          <Text>{data.note}</Text>
                        </Space>
                      )
                    }
                  </Space>)
              })()}
            </Space>
          </Card>
        </Space>
      </Spin>
    }
  ]

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="home-body">
        <main className="container w-full">
          <Spin spinning={loading} tip="Loading..." size="large">
            <>
              {!!ponum ? (
                <>
                  <h2>PO {(po || [])[0]?.po_number} from {(po || [])[0]?.vendor}</h2>
                  <Row>
                    <Col span={24}>
                      <Space size="middle">
                        <Text>Date: <b>{(po || [])[0]?.purchase_date}</b></Text>
                      </Space>
                    </Col>
                  </Row>
                  <Tabs defaultActiveKey="1" items={items} onChange={() => console.log('tab changed')} />
                </>
              ) : (
                <>
                  <Row gutter={[8, 8]}>
                    <Col span={6}>
                      <Select
                        mode="multiple"
                        className="w-full"
                        showSearch
                        allowClear
                        options={dataByField["vendor"] || []}
                        placeholder="Vendor"
                        notFoundContent={loadingDataByField ? <Spin size="small" /> : null}
                        onChange={(value) => setQuery({ ...query, vendor: value })}
                      />
                    </Col>
                    <Col span={15}>
                      <Input
                        className="w-full"
                        placeholder="PO#"
                        onChange={(e) => setQuery({ ...query, po_number: e.target.value.replaceAll('\n', ' ').replaceAll(' ', '\\') })}
                      />
                    </Col>
                    <Col span={3} className="utils">
                      <Button type="primary" disabled={loadingExport} icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />} onClick={() => exportExcel(token, setToken, query, setLoadingExport, setError, openNotification, 'pos')}>
                        Export
                      </Button>
                      <Badge status="success" text={`Qty: ${total}`} className="total" />
                    </Col>
                  </Row>
                  <Table
                    columns={columns}
                    scroll={{ x: 'max-content' }}
                    dataSource={pos.map((d, idx) => {
                      return {
                        key: idx,
                        ...d
                      }
                    })}
                    onChange={handleChange}
                    rowKey="key"
                    pagination={{ total: total }}
                  />
                </>
              )}
            </>
          </Spin>
        </main>
      </div>
    </Context.Provider>
  )
}

export default Po