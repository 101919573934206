import { useState, useEffect, useMemo, createContext } from 'react'
import { useNavigate, Link } from "react-router-dom"
import { Table, Spin, notification, Typography, Badge, Space, Card, Tabs, Button } from "antd"
import { showDetailsInfo, getNotes, exportExcel } from './utils'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography

const Context = createContext({
  status: 'status',
  errorMsg: 'error'
})

const Details = ({ token, setToken }) => {
  let navigate = useNavigate()
  const [testData, setTestData] = useState([])
  const [salesOrders, setSalesOrders] = useState([])
  const [vendorData, setVendorData] = useState([])
  const [odditData, setOdditData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [api, contextHolder] = notification.useNotification()
  const [notes, setNotes] = useState([])
  const [loadingNotes, setLoadingNotes] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)

  const openNotification = placement => {
    api.error({
      message: <Context.Consumer>{({ status }) => status}</Context.Consumer>,
      description: <Context.Consumer>{({ errorMsg }) => `${errorMsg}!`}</Context.Consumer>,
      placement
    })
  }
  useEffect(() => {
    getNotes(openNotification, token, setToken, setNotes, setLoadingNotes, setLoading, setError, window.location.href.split('/')[4])
    showDetailsInfo(token, window.location.href.split('/')[4], setTestData, setOdditData, setVendorData, setSalesOrders, setToken, setError, setLoading, openNotification)
  }, [token])

  const contextValue = useMemo(() => !!error && ({
    status: error.status,
    errorMsg: error.statusText
  }), [error])
  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'project',
      sortDirections: ['descend', 'ascend'],
      key: 'project',
      render: (text, record) => <Link to={`/device?project=${record.project}`}>{text}</Link>,
      sorter: {
        multiple: 1,
        compare: (a, b) => { if (a.project > b.project) return -1; else return 1 }
      }
    },
    {
      title: 'Carton',
      dataIndex: 'carton',
      sortDirections: ['descend', 'ascend'],
      key: 'carton',
      render: (text, record) => <Link to={`/device?carton=${text}`}>{text}</Link>,
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.carton > b.carton) return -1; else return 1 }
      }
    },
    {
      title: 'Carton Description',
      dataIndex: 'carton_desc',
      sortDirections: ['descend', 'ascend'],
      key: 'carton_desc',
      sorter: {
        multiple: 3,
        compare: (a, b) => { if (a.carton_desc > b.carton_desc) return -1; else return 1 }
      }
    },
    {
      title: 'Device Status',
      dataIndex: 'device_status',
      sortDirections: ['descend', 'ascend'],
      key: 'device_status',
      sorter: {
        multiple: 4,
        compare: (a, b) => { if (a.device_status > b.device_status) return -1; else return 1 }
      },
    },
    {
      title: 'Scan Date',
      dataIndex: 'device_modified',
      sortDirections: ['descend', 'ascend'],
      key: 'device_modified',
      sorter: {
        multiple: 5,
        compare: (a, b) => { if (a.device_modified > b.device_modified) return -1; else return 1 }
      }
    },
    {
      title: 'Status',
      dataIndex: 'project_status',
      sortDirections: ['descend', 'ascend'],
      key: 'project_status',
      sorter: {
        multiple: 6,
        compare: (a, b) => { if (a.project_status > b.project_status) return -1; else return 1 }
      }
    },
    {
      title: 'Project Tags',
      dataIndex: 'project_tags',
      sortDirections: ['descend', 'ascend'],
      key: 'project_tags',
      sorter: {
        multiple: 7,
        compare: (a, b) => { if (a.project_tags > b.project_tags) return -1; else return 1 }
      }
    }
  ]

  const testDataColumns = [
    {
      title: 'IMEI',
      dataIndex: 'esn',
      key: 'esn',
      render: (text, record) => text[0],
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 1,
        compare: (a, b) => { if (a.esn > b.esn) return -1; else return 1 }
      }
    },
    {
      title: 'Serial',
      dataIndex: 'serial',
      key: 'serial',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.serial > b.serial) return -1; else return 1 }
      }
    },
    {
      title: 'Date',
      dataIndex: 'tested',
      key: 'tested',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.tested > b.tested) return -1; else return 1 }
      }
    },
    {
      title: 'Software',
      dataIndex: 'software',
      key: 'software',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 3,
        compare: (a, b) => { if (a.software > b.software) return -1; else return 1 }
      }
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 4,
        compare: (a, b) => { if (a.grade > b.grade) return -1; else return 1 }
      }
    },
    {
      title: 'Damages',
      dataIndex: 'damages',
      key: 'damages',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 5,
        compare: (a, b) => { if (a.damages > b.damages) return -1; else return 1 }
      }
    },
    {
      title: 'MODEL',
      dataIndex: 'model',
      key: 'model',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.model > b.model) return -1; else return 1 }
      }
    },
    {
      title: 'Model Name',
      dataIndex: 'model_name',
      key: 'model_name',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.model_name > b.model_name) return -1; else return 1 }
      }
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.color > b.color) return -1; else return 1 }
      }
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.capacity > b.capacity) return -1; else return 1 }
      }
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      render: (text) => Object.keys(text).map(key => <p>{key}: <b>{text[key]}</b></p>)
    },
  ]

  const salesOrdersColumns = [
    {
      title: 'Order Number',
      dataIndex: 'order_number',
      key: 'order_number',
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <Link to={`/order/${text}`}>{text}</Link>,
      sorter: {
        multiple: 1,
        compare: (a, b) => { if (a.order_number > b.order_number) return -1; else return 1 }
      }
    },
    {
      title: 'Customer',
      dataIndex: 'order_customer',
      key: 'order_customer',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 2,
        compare: (a, b) => { if (a.order_customer > b.order_customer) return -1; else return 1 }
      }
    },
    {
      title: 'Date',
      dataIndex: 'order_created',
      key: 'order_created',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 3,
        compare: (a, b) => { if (a.order_created > b.order_created) return -1; else return 1 }
      }
    },
    {
      title: 'Status',
      dataIndex: 'order_status',
      key: 'order_status',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 4,
        compare: (a, b) => { if (a.order_status > b.order_status) return -1; else return 1 }
      }
    },
    {
      title: 'Description',
      dataIndex: 'order_description',
      key: 'order_description',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        multiple: 5,
        compare: (a, b) => { if (a.order_description > b.order_description) return -1; else return 1 }
      }
    }
  ]

  const items = [
    {
      key: '1',
      label: 'ESN Details',
      children: <>
        <div className='p-1'>
          <h2 style={{ marginTop: '0' }}>ESN Details Page</h2>
          <Text>ESN: <b>{window.location.href.split('/')[4]}</b></Text>
        </div>
        <div className='details'>
          <div className='details-header'>Vendor Data</div>
          {vendorData.length > 0 ? <div>
            <div className='details-row'>
              <div className='details-field'>Vendor</div>
              <div className='details-description'>{vendorData[0]?.vendor}</div>
            </div>
            <div className='details-row'>
              <div className='details-field'>Vendor Model</div>
              <div className='details-description'>{vendorData[0]?.model}</div>
            </div>
            <div className='details-row'>
              <div className='details-field'>Vendor Grade</div>
              <div className='details-description'>{vendorData[0]?.vendor_grade}</div>
            </div>
            <div className='details-row'>
              <div className='details-field'>Date</div>
              <div className='details-description'>{vendorData[0]?.purchase_date}</div>
            </div>
            <div className='details-row'>
              <div className='details-field'>PO#</div>
              <Link className='details-description' to={`/po/${vendorData[0]?.po_number}`}>{vendorData[0]?.po_number}</Link>
            </div>
          </div> : <div className='details-row'>No Data</div>}
        </div>
        <div className='details'>
          <div className='details-header'>Sales Orders</div>
          {odditData.length > 0 ?
            <Table
              columns={salesOrdersColumns}
              dataSource={salesOrders}
              scroll={{ x: 'max-content' }}
              rowKey="key"
              pagination={false}
            />
            :
            <div className='details-row'>No Data</div>}
        </div>
        <div className='details'>
          <div className='details-header'>
            Test Records
            <Button
              className="login-form-forgot"
              type="primary"
              disabled={loadingExport || !testData.length}
              icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />}
              onClick={() => exportExcel(openNotification, token, setToken, setLoadingExport, setLoading, setError, window.location.href.split('/')[4])}>
              Export
            </Button>
          </div>
          {testData.length > 0 ?
            <>
              <Table
                columns={testDataColumns}
                dataSource={testData}
                rowKey="key"
                scroll={{ x: 'max-content' }}
                pagination={false}
              />
            </>
            :
            <div className='details-row'>No Data</div>
          }
        </div>
        <div className='details'>
          <div className='details-header'>Oddit Details</div>
          {odditData.length > 0 ?
            <Table
              columns={columns}
              dataSource={odditData}
              scroll={{ x: 'max-content' }}
              rowKey="key"
              pagination={false}
            />
            :
            <div className='details-row'>No Data</div>}
        </div>
      </>
    },
    {
      key: '2',
      label: <Badge count={loadingNotes ? <Spin size="small"></Spin> : notes.length} offset={[15, 0]}><span>Notes</span></Badge>,
      children: <Spin spinning={loadingNotes} tip="Loading..." size="large">
        <Space direction="vertical">
          <Card title="Notes" style={{ width: '300px' }}>
            <Space size="middle" direction="vertical">
              {(() => {
                const groups = notes.map(note => {
                  let temp = new Date(note.added).toLocaleString('en-US').split(', ')
                  return {
                    ...note,
                    date: temp[0],
                    time: temp[1]
                  }
                }).reduce((group, product) => {
                  const { date } = product;
                  group[date] = group[date] ?? [];
                  group[date].push(product);
                  return group;
                }, {})
                return Object.keys(groups).map((date, idx) =>
                  <Space size="small" direction="vertical">
                    <Text><b>{date}</b></Text>
                    {
                      groups[date].map(data =>
                        <Space size="middle" style={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Text style={{ whiteSpace: 'nowrap' }}>{data.time}</Text>
                          <Text>{data.note}</Text>
                        </Space>
                      )
                    }
                  </Space>)
              })()}
            </Space>
          </Card>
        </Space>
      </Spin>
    },
  ]

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Spin spinning={loading} tip="Loading..." size="large">
        <div className="w-full">
          <span className='back-btn' onClick={() => navigate(-1)}>{`< `}Back</span>
          <Tabs className='p-1' defaultActiveKey="1" items={items} onChange={() => console.log('tab changed')} /> :
        </div>
      </Spin>
    </Context.Provider>
  )
}

export default Details