import { useState, useEffect, useMemo, createContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash';
import { Table, Spin, notification, Row, Col, Select, Input, Button, Typography, Switch, Badge, Space, Tabs } from "antd"
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  getDataByField,
  getDataByFieldForPallet,
  getProjects,
  exportExcel,
  getSnapShots,
  getDataByFieldForSnapShot,
  createProject,
  createSnapshot,
  addTag,
  removeTag,
  refresh,
  retire,
  restore,
  getPallets
} from './utils'
import Tags from './Tags'
import { filtersofSnapshot, columnsForPallets } from './constants';
const { TextArea } = Input
const { Text } = Typography;

const Context = createContext({
  status: 'status',
  errorMsg: 'error'
})
const filters = ['status']


const filtersForPallet = [
  {
    label: 'Status',
    field: 'pallet_status'
  },
  {
    label: 'User',
    field: 'pallet_user'
  }
]
// const checks = ['ss1', 'sd3', 'yp2', 'audits', 'sos', 'rmas']
const Project = ({ token, setToken }) => {
  const [projects, setProjects] = useState([])
  const [pallets, setPallets] = useState([])
  const [projectQuery, setProjectQuery] = useState()
  const [palletQuery, setPalletQuery] = useState()
  const [showProjects, setShowProjects] = useState([])
  const [snapshots, setSnapshots] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingForPallets, setLoadingForPallets] = useState(false)
  const [loadingForSnapshot, setLoadingForSnapshot] = useState(false)
  const [queryForSnapshot, setQueryForSnapshot] = useState({})
  const [queryForPallet, setQueryForPallet] = useState({})
  const [query, setQuery] = useState({
    retired: false,
    ss1: false,
    sd3: false,
    sos: false,
    rma: false,
  })
  const [showAll, setShowAll] = useState(false)
  const [dataByField, setDataByField] = useState({})
  const [dataByFieldForPallet, setDataByFieldForPallet] = useState({})
  const [dataByFieldForSnapShot, setDataByFieldForSnapShot] = useState({})
  const [loadingDataByField, setLoadingDataByField] = useState(false)
  const [loadingDataByFieldForPallet, setLoadingDataByFieldForPallet] = useState(false)
  const [loadingDataByFieldForSnapShot, setLoadingDataByFieldForSnapShot] = useState(false)
  const [error, setError] = useState({})
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [sort, setSort] = useState()
  const [totalForSnapShot, setTotalForSnapShot] = useState(0)
  const [limitForSnapShot, setLimitForSnapShot] = useState(10)
  const [offsetForSnapShot, setOffsetForSnapShot] = useState(0)
  const [sortForSnapShot, setSortForSnapShot] = useState()
  const [totalForPallets, setTotalForPallets] = useState(0)
  const [limitForPallets, setLimitForPallets] = useState(10)
  const [offsetForPallets, setOffsetForPallets] = useState(0)
  const [sortForPallets, setSortForPallets] = useState()
  const [api, contextHolder] = notification.useNotification()
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingExportForSnapShot, setLoadingExportForSnapShot] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [columns, setColumns] = useState([])
  const [showColumns, setShowColumns] = useState([])
  const [projectName, setProjectName] = useState('')
  const [snapshotName, setSnapshotName] = useState('')

  const columnsofSnapShot = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        multiple: 1
      },
      render: (text, record) => <Link to={`/snapshot?project=${selectedRowKeys[0]}&name=${record.name}`}>{text}</Link>,
    },
    {
      title: 'user',
      dataIndex: 'user',
      key: 'user',
      sorter: {
        multiple: 2
      }
    },
    {
      title: 'frozen',
      dataIndex: 'frozen',
      key: 'frozen',
      sorter: {
        multiple: 3
      }
    },
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      sorter: {
        multiple: 4
      }
    },
  ]

  const openNotification = placement => {
    api.error({
      message: <Context.Consumer>{({ status }) => status}</Context.Consumer>,
      description: <Context.Consumer>{({ errorMsg }) => `${errorMsg}!`}</Context.Consumer>,
      placement
    })
  }

  const contextValue = useMemo(() => !!error && ({
    status: error.status,
    errorMsg: error.statusText
  }), [error])

  useEffect(() => {
    getDataByField(setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters)
    getDataByFieldForPallet(setDataByFieldForPallet, setLoadingDataByFieldForPallet, setError, openNotification, setLoading, token, setToken, filtersForPallet)
  }, [token])

  useEffect(() => {
    if (query.rma && query.ss1 && query.sd3 && query.sos && query.retired) setShowAll(true)
    else setShowAll(false)
  }, [query])

  useEffect(() => {
    getProjects(setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)
  }, [token, limit, offset, query, columns])

  useEffect(() => {
    getPallets(setPallets, setLoadingForPallets, setTotalForPallets, setError, setToken, openNotification, token, limitForPallets, offsetForPallets, sortForPallets, queryForPallet)
  }, [token, limitForPallets, offsetForPallets, queryForPallet, sortForPallets])

  const handleChange = (pagination, filters, sorter) => {
    const offset1 = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit1 = pagination.pageSize;
    setOffset(offset1)
    setLimit(limit1)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "%2B" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "%2B" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSort(sortStr)
  };

  const handleChangeForSnapshot = (pagination, filters, sorter) => {
    const offset1 = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit1 = pagination.pageSize;
    setOffsetForSnapShot(offset1)
    setLimitForSnapShot(limit1)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "%2B" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "%2B" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSortForSnapShot(sortStr)
  };


  useEffect(() => {
    let col = []
    if (projects.length > 0) {
      col = Object.keys(projects[0]).map((d, idx, self) => {
        if (d === "devices") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            render: (text, record) => <Link to={`/device?project=${record.project}`}>{text}</Link>,
            sorter: {
              compare: (a, b) => a.devices - b.devices,
              multiple: 1,
            },
          }
        } else if (d === "cartons") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            render: (text, record) => <Link to={`/carton?project=${record.project}`}>{text}</Link>,
            sorter: {
              compare: (a, b) => a.cartons - b.cartons,
              multiple: 1,
            },
          }
        } else if (d === "tags") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sorter: {
              multiple: 1,
            },
            render: (text, record) => <Tags text={text} addTag={(tag, setTags, newTags) => addTag(tag, record.project, setTags, newTags, setError, openNotification, setLoading, token, setToken)} removeTag={(tag, setTags, newTags) => removeTag(tag, record.project, setTags, newTags, setError, openNotification, setLoading, token, setToken)} />
          }
        } else if (d === "status") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            render: (text, record) => text === "Stale" ? <Link to={`#`} onClick={() => refresh(record.project, setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)}>{text}</Link>: text,
            sorter: {
              compare: (a, b) => { if (a[d] > b[d]) return -1; if (a[d] < b[d]) return 1; return 0 },
              multiple: 1
            }
          }
        } else {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sortDirections: ['descend', 'ascend'],
            sorter: {
              multiple: 1,
              compare: (a, b) => { if (a[d] > b[d]) return -1; if (a[d] < b[d]) return 1; return 0 }
            },
          }
        }
      })
      if (columns.filter(cl => cl.key !== "count").length === 0) {
        setShowColumns(col)
        setColumns(col)
      }
    }
  }, [projects])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([newSelectedRowKeys[newSelectedRowKeys.length - 1]]);
  };

  useEffect(() => {
    if (selectedRowKeys.filter(d => !!d).length) {
      getDataByFieldForSnapShot(setDataByFieldForSnapShot, setLoadingDataByFieldForSnapShot, setError, openNotification, setLoadingForSnapshot, token, setToken, filtersofSnapshot, selectedRowKeys.join('\\'))
    }
  }, [selectedRowKeys, token])

  useEffect(() => {
    if (selectedRowKeys.filter(d => !!d).length) {
      getSnapShots(setSnapshots, setLoadingForSnapshot, setTotalForSnapShot, setError, setToken, openNotification, token, limitForSnapShot, offsetForSnapShot, sortForSnapShot, queryForSnapshot, selectedRowKeys.join('\\'))
    } else {
      setSnapshots([])
      setTotalForSnapShot(0)
    }
  }, [queryForSnapshot, token, selectedRowKeys])

  useEffect(() => {
    if (!!projectQuery) {
      setShowProjects(projects.filter(project => project.project.toUpperCase().includes(projectQuery.toUpperCase())))
    } else {
      setShowProjects(projects)
    }
  }, [projects, projectQuery])

  const handleChangeForPallet = (pagination, filters, sorter) => {
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    setLimitForPallets(limit)
    setOffsetForPallets(offset)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "+" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "+" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSortForPallets(sortStr)
  };

  const items = [
    {
      key: '1',
      label: 'Projects',
      children:
        <Spin spinning={loading || loadingForSnapshot} tip="Loading..." size="large">
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row gutter={[8, 8]}>
              <Col span={3}>
                <Text strong>New Project Name</Text>
              </Col>
              <Col span={18}>
                <Input
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </Col>
              <Col span={3} className="utils">
                <Button
                  type="primary"
                  onClick={() => createProject(projectName, setProjectName, setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)}
                >
                  Create Project
                </Button>
              </Col>
            </Row>
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => refresh(selectedRowKeys[0], setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)}
                disabled={!(selectedRowKeys.filter(d => !!d).length) || projects.filter(p => p.project === selectedRowKeys[0])[0]?.status !== 'Stale'}
              >
                Refresh Stale Project
              </Button>
              <Button
                type="primary"
                onClick={() => retire(selectedRowKeys[0], setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)}
                disabled={!(selectedRowKeys.filter(d => !!d).length) || !["Active", "Stale"].includes((projects.filter(p => p.project === selectedRowKeys[0])[0]?.status))}
              >
                Retire Active/Stale Project
              </Button>
              <Button
                type="primary"
                onClick={() => restore(selectedRowKeys[0], setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)}
                disabled={!(selectedRowKeys.filter(d => !!d).length) || projects.filter(p => p.project === selectedRowKeys[0])[0]?.status !== ('Retired')}
              >
                Restore Retired Project
              </Button>
            </Space>
            <Row gutter={[8, 8]}>
              <Col span={21}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Input
                      className="w-full"
                      placeholder="Project"
                      onChange={_.debounce((e) => {
                        setProjectQuery(e.target.value)
                      }, 500)}
                    />
                  </Col>
                  {filters.map(filter =>
                    <Col span={12}>
                      <Select
                        mode="multiple"
                        className="w-full"
                        showSearch
                        allowClear
                        options={dataByField[filter] || []}
                        placeholder={filter.charAt(0).toUpperCase() + filter.slice(1)}
                        notFoundContent={loadingDataByField ? <Spin size="small" /> : null}
                        onChange={(value) => setQuery({ ...query, [filter]: value })}
                      />
                    </Col>
                  )}
                  <Col span="4">
                    <Row gutter={[16, 16]}>
                      <Col span="12" style={{ textAlign: 'right' }}>
                        <label htmlFor='retired'>Retired</label>
                      </Col>
                      <Col span="12">
                        <Switch name="retired" id='retired' checked={query.retired} onChange={(checked) => setQuery(() => {
                          return {
                            ...query,
                            retired: checked
                          }
                        })} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span="4">
                    <Row gutter={[8, 8]}>
                      <Col span="12" style={{ textAlign: 'right' }}>
                        <label htmlFor='ss1'>SS1</label>
                      </Col>
                      <Col span="12">
                        <Switch name="ss1" id='ss1' checked={query.ss1} onChange={(checked) => setQuery(() => {
                          return {
                            ...query,
                            ss1: checked
                          }
                        })} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span="4">
                    <Row gutter={[8, 8]}>
                      <Col span="12" style={{ textAlign: 'right' }}>
                        <label htmlFor='sd3'>SD3</label>
                      </Col>
                      <Col span="12">
                        <Switch name="sd3" id='sd3' checked={query.sd3} onChange={(checked) => setQuery(() => {
                          return {
                            ...query,
                            sd3: checked
                          }
                        })} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span="4">
                    <Row gutter={[8, 8]}>
                      <Col span="12" style={{ textAlign: 'right' }}>
                        <label htmlFor='sos'>SOs</label>
                      </Col>
                      <Col span="12">
                        <Switch name="sos" id='sos' checked={query.sos} onChange={(checked) => setQuery(() => {
                          return {
                            ...query,
                            sos: checked
                          }
                        })} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span="4">
                    <Row gutter={[8, 8]}>
                      <Col span="12" style={{ textAlign: 'right' }}>
                        <label htmlFor='rmas'>RMAs</label>
                      </Col>
                      <Col span="12">
                        <Switch name="rmas" id='rmas' checked={query.rma} onChange={(checked) => setQuery(() => {
                          return {
                            ...query,
                            rma: checked,
                          }
                        })} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span="4">
                    <Row gutter={[8, 8]}>
                      <Col span="12" style={{ textAlign: 'right' }}>
                        <label htmlFor='showALL'>Show All</label>
                      </Col>
                      <Col span="12">
                        <Switch name="showALL" id='showALL' checked={showAll} onChange={(checked) => setQuery(() => {
                          return {
                            ...query,
                            rma: checked,
                            retired: checked,
                            ss1: checked,
                            sd3: checked,
                            sos: checked,
                          }
                        })} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Select
                      mode="multiple"
                      className="w-full"
                      allowClear
                      options={showColumns.map(d => {
                        return {
                          label: d.key,
                          value: d.key
                        }
                      })}
                      placeholder="Columns"
                      notFoundContent={showColumns ? <Spin size="small" /> : null}
                      onChange={(value) => {
                        setColumns([...(showColumns.filter(d => value.includes(d.key))), ...(
                          showColumns.filter(d => value.includes(d.key)).map(cl => cl.key).includes('project') ? [] : [{
                            title: 'count',
                            dataIndex: 'count',
                            key: 'count',
                            sorter: {
                              multiple: 1000,
                            }
                          }])])
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={3} className="utils">
                <Button
                  type="primary"
                  disabled={loadingExport}
                  icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />}
                  onClick={() => exportExcel(token, setToken, query, setLoadingExport, setError, openNotification, 'project', null, columns)}
                >
                  Export
                </Button>
              </Col>
            </Row>
            <Badge status="success" text={`Qty: ${showProjects.length}`} className="total" />
            <Table
              scroll={{ x: 'max-content' }}
              columns={columns}
              dataSource={showProjects.map((d, idx) => {
                return {
                  key: idx,
                  ...d
                }
              })}
              onChange={handleChange}
              rowKey={(record) => record.project}
              rowSelection={{ selectedRowKeys, onChange: onSelectChange, getCheckboxProps: (record) => !!record.project ? { disabled: false } : { disabled: true } }}
            />
            <Row gutter={[8, 8]}>
              <h2>Project Snapshots</h2>
              <Col span={24}>
                <Row gutter={[8, 8]}>
                  <Col span={3}>
                    <Text strong>Sanpshot Name</Text>
                  </Col>
                  <Col span={17}>
                    <Input
                      value={snapshotName}
                      onChange={(e) => setSnapshotName(e.target.value)}
                      disabled={!(selectedRowKeys.filter(d => !!d).length)}
                    />
                  </Col>
                  <Col span={4} className="utils">
                    <Button
                      type="primary"
                      disabled={!snapshotName.length}
                      onClick={() => createSnapshot(snapshotName, selectedRowKeys[0], setSnapshotName, setError, openNotification, setLoading, token, setToken)}
                    >
                      Create Project Snapshot
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={21}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextArea
                      className="w-full"
                      placeholder="Name"
                      onChange={(e) => setQueryForSnapshot({ ...queryForSnapshot, name: e.target.value.replaceAll('\n', '\\') })}
                    />
                  </Col>
                  {filtersofSnapshot.map(filter =>
                    <Col span={11}>
                      <Select
                        mode="multiple"
                        className="w-full"
                        showSearch
                        allowClear
                        options={dataByFieldForSnapShot[filter] || []}
                        placeholder={filter.charAt(0).toUpperCase() + filter.slice(1)}
                        notFoundContent={loadingDataByFieldForSnapShot ? <Spin size="small" /> : null}
                        onChange={(value) => setQueryForSnapshot({ ...queryForSnapshot, [filter]: value })}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span={3} className="utils">
                <Space direction="vertical" size="middle" style={{ display: 'flex', alignItems: 'end' }}>
                  {selectedRowKeys.filter(d => !!d).length ? <>
                    <Button
                      type="primary"
                      disabled={loadingExportForSnapShot}
                      icon={loadingExportForSnapShot ? <LoadingOutlined /> : <DownloadOutlined />}
                      onClick={() => exportExcel(token, setToken, queryForSnapshot, setLoadingExportForSnapShot, setError, openNotification, 'snapshot', selectedRowKeys.join('\\'))}
                    >
                      Export
                    </Button>
                  </> : <></>}
                  <Badge status="success" text={`Qty: ${totalForSnapShot}`} className="total" />
                </Space>
              </Col>
            </Row>
            <Table
              columns={columnsofSnapShot}
              scroll={{ x: 'max-content' }}
              dataSource={snapshots}
              onChange={handleChangeForSnapshot}
            />
          </Space>
        </Spin>
    },
    {
      key: '2',
      label: 'Pallets',
      children:
        <Spin spinning={loadingForPallets} tip="Loading..." size="large">
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Input
                className="w-full"
                placeholder="Project"
                onChange={_.debounce((e) => {
                  setQueryForPallet({ ...query, pallet: e.target.value })
                }, 500)}
              />
            </Col>
            {filtersForPallet.map(filter =>
              <Col span={12}>
                <Select
                  mode="multiple"
                  className="w-full"
                  showSearch
                  allowClear
                  options={dataByFieldForPallet[filter.field] || []}
                  placeholder={filter.label}
                  notFoundContent={loadingDataByFieldForPallet ? <Spin size="small" /> : null}
                  onChange={(value) => setQueryForPallet({ ...query, [filter.field]: value })}
                />
              </Col>
            )}
            <Col span={24}>
              <Badge status="success" text={`Qty: ${totalForPallets}`} className="total" />
              <Table
                columns={columnsForPallets}
                dataSource={pallets}
                rowKey={(record) => record.pallet}
                onChange={handleChangeForPallet}
                scroll={{ x: 'max-content' }}
                pagination={{ total: totalForPallets }}
              />
            </Col>
          </Row>
        </Spin>
    },
  ]

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="home-body">
        <main className="container w-full">
          <Tabs defaultActiveKey="1" items={items} onChange={() => console.log('tab changed')} />
        </main>
      </div>
    </Context.Provider >
  )
}

export default Project