import { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, NavLink, Navigate } from "react-router-dom"
import Home from "./pages/Home"
import Login from "./pages/Login"
import Details from "./pages/Details"
import Project from "./pages/Project"
import Device from "./pages/Device"
import Carton from "./pages/Carton"
import SnapShot from "./pages/SnapShot"
import useToken from "./hooks/useToken"
import { Col, Menu, Row } from "antd"
import "./App.css"
import Po from "./pages/Po"
import SalesOrder from "./pages/SalesOrder"

const pages = [
  {
    key: 'po',
    label: <NavLink to='/po'>PO</NavLink>
  },
  {
    key: 'inventory',
    label: <NavLink to='/inventory'>Inventory</NavLink>
  },
  {
    key: 'project',
    label: <NavLink to='/project'>Oddit</NavLink>
  },
  {
    key: 'sales_order',
    label: <NavLink to='/order'>Sales Order</NavLink>
  }
  
]

function App() {
  const {token, setToken} = useToken();
  const [current, setCurrent] = useState(window.location.href.split('/')[3])
  const logout = () => {
    localStorage.clear(); 
    window.location.href="/"
  }
  useEffect(() => {
    setCurrent(window.location.href.split('/')[3])
  }, [])

  if (!token) return <Login setToken={setToken} />

  return (
    <>
      <BrowserRouter>
        <Row>
          <Col span={3} style={{display:'flex', flexDirection: 'column', alignItems: 'space-between', minHeight: '100vh'}}>
            <Menu
              theme="dark"
              mode="vertical"
              selectedKeys={[current]}
              onClick={(e) => setCurrent(e.key)}
              items={pages}
              style={{height: '100%'}}
            />
            <div onClick={logout} className="logout-btn">Logout</div>
          </Col>
          <Col span={21}>
            <Routes>
              <Route path="/inventory" element={<Home token={token} setToken={setToken} />} />
              <Route path="/po/*" element={<Po token={token} setToken={setToken} />} />
              <Route path="/imei/*" element={<Details token={token} setToken={setToken} />} />
              <Route path="/project/*" element={<Project token={token} setToken={setToken} />} />
              <Route path="/carton/*" element={<Carton token={token} setToken={setToken} />} />
              <Route path="/device/*" element={<Device token={token} setToken={setToken} />} />
              <Route path="/snapshot/*" element={<SnapShot token={token} setToken={setToken} />} />
              <Route path="/order/*" element={<SalesOrder token={token} setToken={setToken} />} />
              <Route path='*' element={<Navigate to='/inventory' />} />
            </Routes>
          </Col>
        </Row>
      </BrowserRouter>
    </>
  )
}

export default App