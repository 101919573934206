import { SERVER_API_URL } from "../constants"
import axios from "axios"

export const getProjectsAPI = (token, limit, offset, sortStr, query, columns) => {
  const checks = ['ss1', 'sd3', 'sos', 'rma', 'retired']
  let requestBody = { columns: columns.map(cl => cl.key).join(',') }
  for (const property in query) {
    if (checks.includes(property)) {
      requestBody = {
        ...requestBody,
        [property]: query[property]
      }
    }
    else {
      if (property === "project") {
        if (query[property].length) {
          requestBody = {
            ...requestBody,
            [property]: query[property]
          }
        }
      }
      else {
        if (query[property].length) {
          requestBody = {
            ...requestBody,
            [property]: (query[property]).join('\\')
          }
        }
      }
    }
  }

  return axios.post(`${SERVER_API_URL}/project?limit=${limit}&skip=${offset}${!!sortStr ? `&sort=${sortStr}` : ''}`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const getPalletsAPI = (token, limit, offset, sortStr, query) => {
  let requestBody = {}
  for (const property in query) {
    if (property === "pallet") {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: query[property]
        }
      }
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: (query[property]).join('\\')
        }
      }
    }
  }

  if (!!sortStr) {
    requestBody = {
      ...requestBody,
      sort: sortStr
    }
  }
  return axios.post(`${SERVER_API_URL}/pallet?limit=${limit}&skip=${offset}`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const getProjectInfoAPI = (token, project) => {
  let requestBody = { project: project }

  return axios.post(`${SERVER_API_URL}/project/details`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const createProjectAPI = (project, token) => {
  let requestBody = { project: project }

  return axios.post(`${SERVER_API_URL}/oddit2/project/create`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const getDataByFieldForProjectAPI = (token) => {
  return axios.get(SERVER_API_URL + "/project/field", {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const getDataByFieldForPalletAPI = (token) => {
  return axios.get(SERVER_API_URL + "/pallet/field", {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const addTagAPI = (tag, project, token) => {
  let requestBody = { tags: tag, project: project }

  return axios.post(`${SERVER_API_URL}/oddit2/project/add_tags`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const removeTagAPI = (tag, project, token) => {
  let requestBody = { tags: tag, project: project }

  return axios.post(`${SERVER_API_URL}/oddit2/project/remove_tags`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const updateProjectStatusAPI = (project, status, token) => {
  let requestBody = { project: project }

  return axios.post(`${SERVER_API_URL}/oddit2/project/${status}`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}