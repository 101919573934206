import { getRefreshTokenAPI, getDevicesAPI, exportExcelAPI, getDataByFieldForDeviceAPI } from "../../services"

export const getDevices = async (setDevices, setLoading, setError, setToken, openNotification, token, sortStr, query, moreQuery, columns) => {
  try { 
    setLoading(true)
    let col = columns.filter(cl => cl.key !== "count")
    const {data} = await getDevicesAPI(token, sortStr, query, moreQuery, col)
    
    setDevices(data.data.map(d => {
      return {
        ...d,
        tested: (() => {
          if (!!d.tested) {
            let temp = new Date(d.tested).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric"}).split('/')
            return `${temp[2]}-${temp[0]}-${temp[1]}`
          } else {
            return ''
          }
        })() 
      }
    }))
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getDataByField = async (setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters, moreQuery) => {
  try {
    setLoadingDataByField(true)
    const {data} = await getDataByFieldForDeviceAPI(token, moreQuery)
    filters.map((field) => {
      setDataByField(prev => {
        return {
          ...prev, 
          [field]: ((data || {})[field] || []).map((d, idx) => {
            return {
              label: d,
              value: d
            }
          })
        }
      })
    })
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const exportExcel = async (token, setToken, query, setLoading, setError, openNotification, from, moreQuery, columns) => {
  try {
    setLoading(true)
    // const {data, headers} = await exportExcelAPI(token, query)
    let col = columns.filter(cl => cl.key !== "count")
    const response = await exportExcelAPI(token, query, from, moreQuery, col)
    let url = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    const disposition = response.headers.get('Content-Disposition');
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    if (filename.toLowerCase().startsWith("utf-8''"))
      filename = decodeURIComponent(filename.replace("utf-8''", ''));
    else
      filename = filename.replace(/['"]/g, '');
    link.setAttribute(
      'download',
      `${filename}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setError('')
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}