import axios from "axios"
import { SERVER_API_URL } from "../constants"
import { getQueryStr } from "./utils"

export const getSalesOrdersAPI = (token, skip, limit, query, sort) => {
  let requestBody = {condition_dict: {}}
  for (const property in query) {
    if (property === 'order_number') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          condition_dict: {
            ...requestBody?.condition_dict,
            [property]: query[property]
          }
        }
      }
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          condition_dict: {
            ...requestBody?.condition_dict,
            [property]: (query[property]).join('\\')
          }
        }
      }
    }
  }
  requestBody = {
    ...requestBody,
    sort,
    limit,
    skip
  }
  return axios.post(SERVER_API_URL + "/sales_orders", requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const getProductsForOrderAPI = (token, query, order, columns) => {
  let col = columns.filter(cl => cl.key !== "count")
  let requestBody = { condition_dict: { order_number: decodeURI(order)}, columns: col.map(cl => cl.key).join(',') }
  for (const property in query) {
    if (property === 'product') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          condition_dict: {
            ...requestBody?.condition_dict,
            [property]: query[property]
          }
        }
      }
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          condition_dict: {
            ...requestBody?.condition_dict,
            [property]: (query[property]).join('\\')
          }
        }
      }
    }
  }
  return axios.post(SERVER_API_URL + "/sales_orders/details", requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const getDataByFieldAPIForSalesOrders = (token) => {
  return axios.get(SERVER_API_URL + "/sales_orders/field", {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const getDataByFieldAPIForProducts = (token) => {
  return axios.get(SERVER_API_URL + "/sales_orders/details/field", {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const updateStatusAPI = (token, order, status, fieldName) => {
  let requestBody = {
    order: order,
    [fieldName]: status
  }
  return axios.post(SERVER_API_URL + "/sales_orders/update_status", requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const updatePriceAPI = (token, open, price, isBulk, selected) => {
  let requestBody = {
    open: isBulk ? selected.product_price : selected.product,
    price: price,
    isBulk: isBulk,
  }
  return axios.post(SERVER_API_URL + "/sales_orders/update_price", requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}