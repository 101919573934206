import {
  getRefreshTokenAPI,
  getProjectsAPI,
  exportExcelAPI,
  getDataByFieldForProjectAPI,
  getDataByFieldForPalletAPI,
  getSnapShotsAPI,
  getDataByFieldForSnapShotAPI,
  createProjectAPI,
  createSnapshotAPI,
  addTagAPI,
  removeTagAPI,
  updateProjectStatusAPI,
  getProjectInfoAPI,
  getPalletsAPI
} from "../../services"

export const getProjects = async (setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sortStr, query, columns) => {
  try {
    setLoading(true)
    let col = columns.filter(cl => cl.key !== "count")
    const { data } = await getProjectsAPI(token, limit, offset, sortStr, query, col)
    setProjects(data.data)
    setTotal(data.total_count)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    }
  } finally {
    setLoading(false)
  }
}

export const getPallets = async (setPallets, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sortStr, query) => {
  try {
    setLoading(true)
    const { data } = await getPalletsAPI(token, limit, offset, sortStr, query)
    setPallets(data.data.map(d => {
      return {
        ...d,
        pallet_modified: (() => {
          let temp = new Date(d.pallet_modified).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
          return `${temp[2]}-${temp[0]}-${temp[1]}`
        })(),
      }
    }))
    setTotal((data.data[0]?.total_count || 0))
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    }
  } finally {
    setLoading(false)
  }
}

export const getProjectInfo = async (setProject, setLoading, setError, openNotification, token, setToken, project) => {
  try {
    setLoading(true)
    const { data } = await getProjectInfoAPI(token, decodeURI(project.split('=')[1]))
    setProject({
      ...data,
      project_created: (() => {
        let temp = new Date(data.project_created).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
        return `${temp[2]}-${temp[0]}-${temp[1]}`
      })(),
      project_modified: (() => {
        let temp = new Date(data.project_modified).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
        return `${temp[2]}-${temp[0]}-${temp[1]}`
      })()
    })
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getSnapShots = async (setSnapshots, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sortStr, query, project) => {
  try {
    setLoading(true)
    const { data } = await getSnapShotsAPI(token, limit, offset, sortStr, query, project)
    setSnapshots(data.data)
    setTotal(data.total_count)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getDataByField = async (setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters) => {
  try {
    setLoadingDataByField(true)
    const { data } = await getDataByFieldForProjectAPI(token)
    filters.map((field) => {
      if (field === "status") {
        setDataByField(prev => {
          return {
            ...prev,
            [field]: ((data || {})['project_status'] || []).map((d, idx) => {
              return {
                label: d,
                value: d
              }
            })
          }
        })
      } else {
        setDataByField(prev => {
          return {
            ...prev,
            [field]: ((data || {})[field] || []).map((d, idx) => {
              return {
                label: d,
                value: d
              }
            })
          }
        })
      }
    })
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const getDataByFieldForPallet = async (setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters) => {
  try {
    setLoadingDataByField(true)
    const { data } = await getDataByFieldForPalletAPI(token)
    console.log(data)
    filters.map((filter) => {
      setDataByField(prev => {
        return {
          ...prev,
          [filter.field]: ((data || {})[filter.field] || []).map((d, idx) => {
            return {
              label: d,
              value: d
            }
          })
        }
      })
    })
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const getDataByFieldForSnapShot = async (setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters, project) => {
  try {
    setLoadingDataByField(true)
    const { data } = await getDataByFieldForSnapShotAPI(token, project)
    filters.map((field) => {
      if (field === "user") {
        setDataByField(prev => {
          return {
            ...prev,
            [field]: ((data || {})['frozen_by'] || []).map((d, idx) => {
              return {
                label: d,
                value: d
              }
            })
          }
        })
      } else {
        setDataByField(prev => {
          return {
            ...prev,
            [field]: ((data || {})[field] || []).map((d, idx) => {
              return {
                label: d,
                value: d
              }
            })
          }
        })
      }
    })
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const exportExcel = async (token, setToken, query, setLoading, setError, openNotification, from, moreQuery, columns) => {
  try {
    setLoading(true)
    // const {data, headers} = await exportExcelAPI(token, query)
    let col = columns.filter(cl => cl.key !== "count")
    const response = await exportExcelAPI(token, query, from, moreQuery, col)
    let url = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    const disposition = response.headers.get('Content-Disposition');
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    if (filename.toLowerCase().startsWith("utf-8''"))
      filename = decodeURIComponent(filename.replace("utf-8''", ''));
    else
      filename = filename.replace(/['"]/g, '');
    link.setAttribute(
      'download',
      `${filename}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setError('')
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const createProject = async (projectName, setProjectName, setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns) => {
  try {
    setLoading(true)
    const { data } = await createProjectAPI(projectName, token)
    if (!!data.data.project) {
      await getProjects(setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)
      setError('')
    }
    else {
      setError({ status: '500', statusText: "Falied Project Creation" })
      openNotification('topRight')
    }
    setProjectName('')
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const createSnapshot = async (name, project, setSnapshotName, setError, openNotification, setLoading, token, setToken) => {
  try {
    setLoading(true)
    const data = await createSnapshotAPI(name, project, token)
    console.log(data)
    setSnapshotName('')
    setError('')
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const addTag = async (tag, project, setTags, newTags, setError, openNotification, setLoading, token, setToken) => {
  try {
    setLoading(true)
    const { data } = await addTagAPI(tag, project, token)
    if (data.data.oddit_project_tags_add) {
      setTags(newTags)
      setError('')
    } else {
      setError({ status: '500', statusText: "Falied add tag" })
      openNotification('topRight')
    }
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const removeTag = async (tag, project, setTags, newTags, setError, openNotification, setLoading, token, setToken) => {
  try {
    setLoading(true)
    const { data } = await removeTagAPI(tag, project, token)
    if (data.data.oddit_project_tags_remove) {
      setTags(newTags)
      setError('')
    } else {
      setError({ status: '500', statusText: "Falied remove tag" })
      openNotification('topRight')
    }
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const refresh = async (project, setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns) => {
  try {
    setLoading(true)
    const { data } = await updateProjectStatusAPI(project, 'refresh', token)
    if (data.status === "success") {
      await getProjects(setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)
      setError('')
    }
    else {
      setError({ status: '500', statusText: "Falied to update status" })
      openNotification('topRight')
    }
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const retire = async (project, setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns) => {
  try {
    setLoading(true)
    const { data } = await updateProjectStatusAPI(project, 'retire', token)
    if (data.status === "success") {
      await getProjects(setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)
      setError('')
    }
    else {
      setError({ status: '500', statusText: "Falied to update status" })
      openNotification('topRight')
    }
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const restore = async (project, setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns) => {
  try {
    setLoading(true)
    const { data } = await updateProjectStatusAPI(project, 'restore', token)
    if (data.status === "success") {
      await getProjects(setProjects, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, columns)
      setError('')
    }
    else {
      setError({ status: '500', statusText: "Falied to update status" })
      openNotification('topRight')
    }
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response?.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}