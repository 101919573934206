import { getInventoriesAPI, getDataByFieldAPI, exportExcelAPI, getDetailsAPI } from "../../services"
import { getRefreshTokenAPI } from "../../services/auth"

export const getInventories = async (openNotification, token, setToken, setInventories, setLoading, setTotal, setError, offset, limit, query = "", sortStr, columns) => {
  try {
    setLoading(true)
    let col = columns.filter(cl => cl.key !== "count")
    const { data } = await getInventoriesAPI(token, offset, limit, query, sortStr, col)
    setInventories([...data.response])
    setTotal(data.total)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const handleFieldChange = async (setError, openNotification, setDataByField, setLoading, setLoadingDataByField, token, setToken, filters) => {
  try {
    setLoadingDataByField(true)
    const {data} = await getDataByFieldAPI(token)
    filters.map((field) => {
      setDataByField(prev => {
        return {
          ...prev, 
          [field]: ((data || {})[field] || []).map((d, idx) => {
            return {
              label: d,
              value: d
            }
          })
        }
      })
    })
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const exportExcel = async (token, setToken, query, setLoading, setError, openNotification, columns) => {
  try {
    setLoading(true)
    // const {data, headers} = await exportExcelAPI(token, query)
    let col = columns.filter(cl => cl.key !== "count")
    const response = await exportExcelAPI(token, query, null, null, col)
    let url = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    const disposition = response.headers.get('Content-Disposition');
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    if (filename.toLowerCase().startsWith("utf-8''"))
      filename = decodeURIComponent(filename.replace("utf-8''", ''));
    else
      filename = filename.replace(/['"]/g, '');
    link.setAttribute(
      'download',
      `${filename}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setError('')
  } catch (error) {
    console.log("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const showDetailsInfo = async (token, imei, setShowDetails, setTestData, setOdditData, setVendorData, setToken, setError, setLoading, openNotification) => {
  try {
    setLoading(true)
    const {data} = await getDetailsAPI(token, imei)
    setTestData(data.testData)
    setOdditData(data.odditData.map(d => {
      return {
        ...d,
        project_tags: ((d.project_data || {})["Tags"] || []).length > 0?((d.project_data || {})["Tags"] || []).join(', ') : 'Empty'
      }
    }))
    setVendorData(data.vendorData)
    setShowDetails(imei)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}