import { useState, useEffect, useMemo, createContext } from 'react'
import { Link } from 'react-router-dom'
import { Table, Spin, notification, Row, Col, Select, Input, Button, Badge, Space, Typography } from "antd"
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { getDataByField, getDevices, exportExcel } from './utils'
import {getProjectInfo} from '../Project/utils'
const { TextArea } = Input
const { Text } = Typography

const Context = createContext({
  status: 'status',
  errorMsg: 'error'
})
const filters = ['carton', 'model', 'capacity', 'color', 'damages', 'vendor_grade']
const Device = ({ token, setToken }) => {
  const [devices, setDevices] = useState([])
  const [project, setProject] = useState()
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({})
  const [dataByField, setDataByField] = useState({})
  const [loadingDataByField, setLoadingDataByField] = useState(false)
  const [error, setError] = useState({})
  const [sort, setSort] = useState()
  const [api, contextHolder] = notification.useNotification()
  const [loadingExport, setLoadingExport] = useState(false)
  const [columns, setColumns] = useState([])
  const [showColumns, setShowColumns] = useState([])

  const openNotification = placement => {
    api.error({
      message: <Context.Consumer>{({ status }) => status}</Context.Consumer>,
      description: <Context.Consumer>{({ errorMsg }) => `${errorMsg}!`}</Context.Consumer>,
      placement
    })
  }

  const contextValue = useMemo(() => !!error && ({
    status: error.status,
    errorMsg: error.statusText
  }), [error])

  useEffect(() => {
    getProjectInfo(setProject, setLoadingDetails, setError, openNotification, token, setToken, (window.location.href.split('?')[1] || ''))
    getDataByField(setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters, (window.location.href.split('?')[1] || ''))
  }, [token])

  useEffect(() => {
    let col = []
    if (devices.length > 0) {
      col = Object.keys(devices[0]).map((d, idx) => {
        if (d === "device") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sortDirections: ['descend', 'ascend'],
            sorter: {
              multiple: 1,
              compare: (a, b) => { if (a[d] > b[d]) return -1; else return 1 }
            },
            render: (text, record) => <Link to={`/imei/${text}`}>{text}</Link>,
          }
        } else {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sortDirections: ['descend', 'ascend'],
            sorter: {
              multiple: 1,
              compare: (a, b) => { if (a[d] > b[d]) return -1; else return 1 }
            },
          }
        }
      })
      if (columns.filter(cl => cl.key !== "count").length === 0) {
        setShowColumns(col)
        setColumns(col)
      }
    }
  }, [devices])

  useEffect(() => {
    getDevices(setDevices, setLoading, setError, setToken, openNotification, token, sort, query, (window.location.href.split('?')[1] || ''), columns)
  }, [token, sort, query, columns])

  // const handleChange = (pagination, filters, sorter) => {
  //   let sortStr = ""
  //   if (Array.isArray(sorter)) {
  //     sorter.map((s, idx) => {
  //       if (idx !== 0)
  //         sortStr += " "
  //       if (!!s.order && s.order === "ascend")
  //         sortStr += "%2B" + s.field
  //       else
  //         sortStr += "-" + s.field
  //       return s
  //     })
  //   } else {
  //     if (!!sorter.order) {
  //       if (sorter.order === "ascend")
  //         sortStr += "%2B" + sorter.field
  //       else
  //         sortStr += "-" + sorter.field
  //     }
  //   }
  //   setSort(sortStr)
  // };

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="home-body">
        <main className="container w-full">
          <Spin spinning={loading} tip="Loading..." size="large">
            <h2>Devices for Oddit Project: {loadingDetails? <Spin /> : project?.project}</h2>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Space size="middle">
                  <Text>Project Created Date: <b>{loadingDetails? <Spin /> : project?.project_created}</b></Text>
                  <Text>Project Last Modified Date: <b>{loadingDetails? <Spin /> : project?.project_modified}</b></Text>
                  <Text>Project Tags: <b>{loadingDetails? <Spin /> : project?.project_data?.Tags?.join(', ')}</b></Text>
                  <Text>Project Status: <b>{loadingDetails? <Spin /> : project?.project_status}</b></Text>
                </Space>
              </Col>
              <Col span={21}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col span={18}>
                        <Row gutter={[16, 16]}>
                          {filters.map(filter =>
                            <Col span={6}>
                              <Select
                                mode="multiple"
                                className="w-full"
                                showSearch
                                allowClear
                                options={dataByField[filter] || []}
                                placeholder={filter.charAt(0).toUpperCase() + filter.slice(1)}
                                notFoundContent={loadingDataByField ? <Spin size="small" /> : null}
                                onChange={(value) => setQuery({ ...query, [filter]: value })}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col span={6}>
                        <TextArea
                          className="w-full"
                          placeholder="Device"
                          onChange={(e) => setQuery({ ...query, device: e.target.value.replaceAll('\n', ' ').replaceAll(' ', '\\') })}
                          rows={3}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Select
                      mode="multiple"
                      className="w-full"
                      allowClear
                      options={showColumns.map(d => {
                        return {
                          label: d.key,
                          value: d.key
                        }
                      })}
                      placeholder="Columns"
                      notFoundContent={showColumns ? <Spin size="small" /> : null}
                      onChange={(value) => {
                        setColumns([...(showColumns.filter(d => value.includes(d.key))), ...((showColumns.filter(d => value.includes(d.key))).map(cl => cl.key).includes('device') ? [] : [{
                          title: 'count',
                          dataIndex: 'count',
                          key: 'count',
                          sorter: {
                            multiple: 1000,
                          }
                        }])])
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={3} className="utils">
                <Button
                  type="primary"
                  disabled={loadingExport}
                  icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />}
                  onClick={() => exportExcel(token, setToken, query, setLoadingExport, setError, openNotification, 'device', (window.location.href.split('?')[1] || ''), columns)}
                >
                  Export
                </Button>
              </Col>
            </Row>
            <Badge status="success" text={`Qty: ${devices.length}`} className="total" />
            <Table
              columns={columns}
              dataSource={devices.map((d, idx) => {
                return {
                  key: idx,
                  ...d
                }
              })}
              // onChange={handleChange}
              scroll={{ x: 'max-content' }}
              rowKey="key"
              className='my-table'
            />
          </Spin>
        </main>
      </div>
    </Context.Provider >
  )
}

export default Device