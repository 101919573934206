import { useState, useEffect, useMemo, createContext } from "react"
import { Row, Col, Select, Input, Table, Badge, Spin, Button, notification } from "antd"
import { Link } from "react-router-dom";
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { getInventories, handleFieldChange, exportExcel } from "./utils"
import { filters } from "./constants"
const { TextArea } = Input

const Context = createContext({
  status: '500',
  errorMsg: 'Internal Server Error'
})

const Home = ({ token, setToken }) => {
  const [loading, setLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [columns, setColumns] = useState([])
  const [showColumns, setShowColumns] = useState([])
  const [loadingDataByField, setLoadingDataByField] = useState(false)
  const [inventories, setInventories] = useState([])
  const [dataByField, setDataByField] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [sort, setSort] = useState()
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [query, setQuery] = useState({})
  const [error, setError] = useState({})
  const [api, contextHolder] = notification.useNotification()

  const openNotification = (placement) => {
    api.error({
      message: <Context.Consumer>{({ status }) => status}</Context.Consumer>,
      description: <Context.Consumer>{({ errorMsg }) => `${errorMsg}!`}</Context.Consumer>,
      placement
    })
  }

  const contextValue = useMemo(() => !!error && ({
    status: error.status,
    errorMsg: error.statusText
  }), [error])

  const handleEsnSearch = (e) => {
    setQuery({ ...query, esn: e.target.value.replaceAll('\n', '\\') })
  }

  const handleChange = (pagination, filters, sorter) => {
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    setPageSize(limit)
    setOffset(offset)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "+" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "+" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSort(sortStr)
  };

  useEffect(() => {
    handleFieldChange(setError, openNotification, setDataByField, setLoading, setLoadingDataByField, token, setToken, filters)
  }, [token])

  useEffect(() => {
    getInventories(openNotification, token, setToken, setInventories, setLoading, setTotal, setError, offset, pageSize, query, sort, columns)
  }, [query, token, offset, sort, columns])

  useEffect(() => {
    let col = []
    if (inventories.length > 0) {
      col = Object.keys(inventories[0]).filter((key, i, self) => self.includes('esn') ? (key !== 'count' && key !== 'rowcount'): key !== 'rowcount').map((d, idx) => {
        if (d === "esn") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sorter: {
              multiple: 1,
            },
            render: (text, record) => <Link to={`/imei/${text}`}>{text}</Link>,
          }
        } else if (d === "po_number") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sorter: {
              multiple: 1,
            },
            render: (text, record) => <div style={{ display: 'flex' }}>{(text || '').split(',').map(po => <Link to={`/po/${po}`}>{po}</Link>)}</div>,
          }
        }
        //  else if (d === "count") {
        //   return {
        //     title: d,
        //     dataIndex: d,
        //     key: d,
        //     sorter: {
        //       multiple: idx + 1,
        //     },
        //     render: (text, record) => <Link to={`/imei/${text}`}>{text}</Link>,
        //   }
        // }
        else {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sorter: {
              multiple: 1,
            },
          }
        }
      })
      if (columns.filter(cl => cl.key !== "count").length === 0) {
        setShowColumns(col)
        setColumns(col)
      }
    }
  }, [inventories])

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="home-body">
        <main className="container">
          <Spin spinning={loading} tip="Loading..." size="large">
            <Row gutter={[8, 8]}>
              <Col span={15}>
                <Row gutter={[16, 16]}>
                  {
                    filters.map(filter =>
                      <Col span={6}>
                        <Select
                          key={filter}
                          mode="multiple"
                          className="w-full"
                          showSearch
                          allowClear
                          options={dataByField[filter] || []}
                          placeholder={filter.charAt(0).toUpperCase() + filter.slice(1)}
                          notFoundContent={loadingDataByField ? <Spin size="small" /> : null}
                          onChange={(value) => setQuery({ ...query, [filter]: value })}
                        />
                      </Col>
                    )
                  }
                </Row>
              </Col>
              <Col span={6}>
                <TextArea
                  className="w-full"
                  placeholder="ESN"
                  onChange={handleEsnSearch}
                  rows={3}
                />
              </Col>
              <Col span={3} className="utils">
                <Button
                  type="primary"
                  disabled={loadingExport}
                  icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />}
                  onClick={() => exportExcel(token, setToken, query, setLoadingExport, setError, openNotification, columns)}>
                  Export
                </Button>
              </Col>
              <Col span={24}>
                <Select
                  mode="multiple"
                  className="w-full"
                  allowClear
                  options={showColumns.map(d => {
                    return {
                      label: d.key,
                      value: d.key
                    }
                  })}
                  placeholder="Columns"
                  notFoundContent={showColumns ? <Spin size="small" /> : null}
                  onChange={(value) => {
                    setColumns([...(showColumns.filter(d => value.includes(d.key))), ...((showColumns.filter(d => value.includes(d.key))).map(cl => cl.key).includes('esn') ? [] : [{
                      title: 'count',
                      dataIndex: 'count',
                      key: 'count',
                      sorter: {
                        multiple: 1000,
                      }
                    }])])
                  }}
                />
              </Col>
              <Col span={24}>
                <Badge status="success" text={`Qty: ${total}`} className="total" />
                <Table
                  columns={columns}
                  dataSource={inventories}
                  rowKey={(record) => record.esn}
                  onChange={handleChange}
                  scroll={{ x: 'max-content' }}
                  pagination={{ total: total }}
                />
              </Col>
            </Row>
          </Spin>
        </main>
      </div>
    </Context.Provider>
  )
}

export default Home