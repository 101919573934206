import axios from "axios"
import { SERVER_API_URL } from "../constants"
import { getQueryStr } from "./utils"

export const getInventoriesAPI = (token, skip, limit, query, sort, columns) => {
  let requestBody = {columns: columns.map(cl => cl.key).join(','), limit, skip, sort, condition_dict: {}}
  for (const property in query) {
    if (property === 'esn') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          condition_dict: {
            ...requestBody.condition_dict,
            [property]: query[property]
          }
        }
      }  
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          condition_dict: {
            ...requestBody.condition_dict,
            [property]: (query[property]).join('\\')
          }
        }
      }  
    }
  }
  return axios.post(SERVER_API_URL + "/frontend", requestBody, {
      headers: {
        "Authorization": token,
        "content-type": "application/json",
        "Access-Control-Allow-Origin": SERVER_API_URL
      },
    })
}
  
export const getDataByFieldAPI = (token) => {
  return axios.get(SERVER_API_URL + "/frontend/field", {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const exportExcelAPI = (token, query, from, po, columns) => {
  let requestBody
  if (!!columns) {
    requestBody = {columns: columns.map(cl => cl.key).join(',')}
  }
  for (const property in query) {
    if (property === 'esn' || property === 'po_number' || property === 'imei' || property === "carton" || property === "project" || property === "device") {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: query[property]
        }
      }  
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: (query[property]).join('\\')
        }
      }  
    }
  }
  let url
  switch(from) {
    case 'pos':
      url = "/po/export_csv"
      break;
    case 'po':
      url = "/po/export_csv"
      requestBody = {
        ...requestBody,
        po_number: po
      }
      break;
    case 'carton':
      url = "/carton/export_csv?project=" + po
      break;
    case 'device':
      url = "/device/export_csv?" + po
      break;
    case 'project':
      url = "/project/export_csv"
      break;
    case 'snapshot':
      url = "/snapshot/export_csv?project=" + po 
      break;
    case 'snapshotDetails':
      url = "/snapshot/details/export_csv?" + po 
      break;
    case 'sales_orders':
      url = '/sales_orders/export_csv'
      if (!!po) {
        requestBody = {
          ...requestBody,
          order: decodeURI(po)
        }
      }
      break;
    case 'test':
      url = "/po/device/export_csv?imei=" + po
      break;
    default:
      url = "/frontend/export_csv"
  }
  return axios.post(SERVER_API_URL + url, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
    responseType: 'blob'
  })
}

export const getNotesAPI = (token, from, num) => {
  return axios.get(SERVER_API_URL + `/${from}/notes/${num}`, {
    headers: {
      "Authorization": token,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}