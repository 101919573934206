import { Link } from "react-router-dom"

export const columnsForPallets = [
  {
    title: 'Pallet',
    dataIndex: 'pallet',
    key: 'pallet',
    sorter: {
      multiple: 1
    },
    // render: (text, record) => <Link to={`/snapshot?project=${selectedRowKeys[0]}&name=${record.name}`}>{text}</Link>,
  },
  {
    title: 'Last Modified',
    dataIndex: 'pallet_modified',
    key: 'pallet_modified',
    sorter: {
      multiple: 2
    },
  },
  {
    title: 'Status',
    dataIndex: 'pallet_status',
    key: 'pallet_status',
    sorter: {
      multiple: 3
    },
  },
  {
    title: 'Description',
    dataIndex: 'pallet_desc',
    key: 'pallet_desc',
    sorter: {
      multiple: 4
    },
  },
  {
    title: 'User',
    dataIndex: 'pallet_user',
    key: 'pallet_user',
    sorter: {
      multiple: 5
    },
  },
  {
    title: 'Cartons',
    dataIndex: 'cartons_count',
    key: 'cartons_count',
    sorter: {
      multiple: 6
    },
  },
  {
    title: 'Devices',
    dataIndex: 'devices_count',
    key: 'devices_count',
    sorter: {
      multiple: 7
    },
  },
]

export const filtersofSnapshot = ["user"]