import {
  getSalesOrdersAPI,
  getDataByFieldAPIForSalesOrders,
  exportExcelAPI,
  getDetailsAPI,
  getProductsForOrderAPI,
  getDataByFieldAPIForProducts,
  getOrderDescAPI,
  getNotesAPI,
  updateStatusAPI,
  updatePriceAPI
} from "../../services"
import { getRefreshTokenAPI } from "../../services/auth"

export const getSalesOrders = async (openNotification, token, setToken, setSalesOrders, setLoading, setTotal, setError, offset, limit, query = "", sortStr) => {
  try {
    setLoading(true)
    const { data } = await getSalesOrdersAPI(token, offset, limit, query, sortStr)
    setSalesOrders(data.response)
    setTotal(data?.total)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getProductsForOrder = async (openNotification, token, setToken, setProducts, setLoading, setTotal, setError, query = "", order, col) => {
  try {
    setLoading(true)
    const { data } = await getProductsForOrderAPI(token, query, order, col)
    setProducts(data)
    setTotal(data?.length || 0)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getOrderDesc = async (openNotification, token, setToken, setProduct, setLoading, setError, order) => {
  try {
    setLoading(true)
    const { data } = await getProductsForOrderAPI(token, {}, order, [])
    setProduct(data[0])
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const handleFieldChange = async (setError, openNotification, setDataByField, setLoading, setLoadingDataByField, token, setToken, filters) => {
  try {
    setLoadingDataByField(true)
    const { data } = await getDataByFieldAPIForSalesOrders(token)
    filters.map((field) => {
      setDataByField(prev => {
        return {
          ...prev,
          [field]: ((data || {})[field] || []).map((d, idx) => {
            return {
              label: d,
              value: d
            }
          })
        }
      })
    })
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const getDataByField = async (setError, openNotification, setDataByField, setLoading, setLoadingDataByField, token, setToken, filters) => {
  try {
    setLoadingDataByField(true)
    const { data } = await getDataByFieldAPIForProducts(token)
    filters.map((field) => {
      setDataByField(prev => {
        return {
          ...prev,
          [field]: ((data || {})[field] || []).map((d, idx) => {
            return {
              label: d,
              value: d
            }
          })
        }
      })
    })
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const exportExcel = async (token, setToken, query, setLoading, setError, openNotification, from, po, col) => {
  try {
    setLoading(true)
    const response = await exportExcelAPI(token, query, from, po, col)
    let url = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    const disposition = response.headers.get('Content-Disposition');
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    if (filename.toLowerCase().startsWith("utf-8''"))
      filename = decodeURIComponent(filename.replace("utf-8''", ''));
    else
      filename = filename.replace(/['"]/g, '');
    link.setAttribute(
      'download',
      `${filename}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setError('')
  } catch (error) {
    console.log("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getNotes = async (openNotification, token, setToken, setNotes, setLoadingNotes, setLoading, setError, order) => {
  try {
    setLoadingNotes(true)
    const { data } = await getNotesAPI(token, 'sales_orders', order)
    setNotes((data || []))
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingNotes(false)
  }
}

export const changeStatus = async (openNotification, token, setToken, setLoading, setError, status, setOpen, order, setStatusLoading, offset, limit, query = "", sortStr, setSalesOrders, setTotal, setProduct, so, fieldName) => {
  try {
    setLoading(true)
    const { data } = await updateStatusAPI(token, order, status, fieldName)
    if (!!so) {
      await getOrderDesc(openNotification, token, setToken, setProduct, setLoading, setError, so)
    } else {
      await getSalesOrders(openNotification, token, setToken, setSalesOrders, setLoading, setTotal, setError, offset, limit, query, sortStr)
    }
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
    // setOpen('')
  }
}

export const changePrice = async (openNotification, token, setToken, setLoading, setError, setOpen, setPriceLoading, col, open, price, selected, query, order, setTotal, setProducts, setPrice) => {
  try {
    setLoading(true)
    await updatePriceAPI(token, selected.product, price, !col.map(c => c.title).includes('product'), selected)
    await getProductsForOrder(openNotification, token, setToken, setProducts, setLoading, setTotal, setError, query, order, col)
    setError('')
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
    setOpen('')
    setPrice('')
  }
}