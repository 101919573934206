import axios from "axios"
import { SERVER_API_URL } from "../constants"

export const loginAPI = (username, password) => {
  return axios.post(`${SERVER_API_URL}/token`, {
    username: username,
    password: password,
    include_refresh_token: true
  }, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    }
  })
}

export const getRefreshTokenAPI = () => {
  return axios.post(`${SERVER_API_URL}/token`, {
    grant_type: 'refresh_token',
    refresh_token: localStorage.getItem('refresh_token')
  }, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    }
  })
}