import { useState, useEffect, useMemo, createContext } from 'react'
import { Link } from 'react-router-dom'
import { Table, Spin, notification, Row, Col, Select, Input, Button, Badge, Space, Typography } from "antd"
import { getDataByField, getCartons, exportExcel } from './utils'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { getProjectInfo } from '../Project/utils'
const { TextArea } = Input
const { Text } = Typography

const Context = createContext({
  status: 'status',
  errorMsg: 'error'
})
const filters = [
  {
    label: 'description',
    field: 'carton_desc'
  }, {
    label: 'status',
    field: 'carton_status'
  }, {
    label: 'user',
    field: 'carton_user'
  }]
const Carton = ({ token, setToken }) => {
  const [cartons, setCartons] = useState([])
  const [project, setProject] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [query, setQuery] = useState({})
  const [dataByField, setDataByField] = useState({})
  const [loadingDataByField, setLoadingDataByField] = useState(false)
  const [error, setError] = useState({})
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [sort, setSort] = useState()
  const [api, contextHolder] = notification.useNotification()
  const [loadingExport, setLoadingExport] = useState(false)

  const openNotification = placement => {
    api.error({
      message: <Context.Consumer>{({ status }) => status}</Context.Consumer>,
      description: <Context.Consumer>{({ errorMsg }) => `${errorMsg}!`}</Context.Consumer>,
      placement
    })
  }

  const contextValue = useMemo(() => !!error && ({
    status: error.status,
    errorMsg: error.statusText
  }), [error])

  useEffect(() => {
    getProjectInfo(setProject, setLoadingDetails, setError, openNotification, token, setToken, (window.location.href.split('?')[1] || ''))
    getDataByField(setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters, window.location.href.split('?')[1].split('=')[1])
  }, [token])

  useEffect(() => {
    getCartons(setCartons, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sort, query, window.location.href.split('?')[1].split('=')[1])
  }, [token, limit, offset, sort, query])

  const handleChange = (pagination, filters, sorter) => {
    const offset1 = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit1 = pagination.pageSize;
    setOffset(offset1)
    setLimit(limit1)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "%2B" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "%2B" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSort(sortStr)
  };

  const columns = [
    {
      title: 'Carton',
      dataIndex: 'carton',
      key: 'carton',
      sorter: {
        multiple: 2
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: {
        multiple: 1
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: {
        multiple: 3
      },
    },
    {
      title: 'user',
      dataIndex: 'user',
      key: 'user',
      sorter: {
        multiple: 4
      },
    },
    {
      title: 'Devices',
      dataIndex: 'devices',
      key: 'devices',
      sorter: {
        multiple: 5
      },
      render: (text, record) => <Link to={`/device?carton=${record.carton}`}>{text}</Link>,
    },
  ]

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="home-body">
        <main className="container w-full">
          <Spin spinning={loading} tip="Loading..." size="large">
            <h2>Cartons for Oddit Project: {loadingDetails ? <Spin /> : project?.project}</h2>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Space size="middle">
                  <Text>Project Created Date: <b>{loadingDetails ? <Spin /> : project?.project_created}</b></Text>
                  <Text>Project Last Modified Date: <b>{loadingDetails ? <Spin /> : project?.project_modified}</b></Text>
                  <Text>Project Tags: <b>{loadingDetails ? <Spin /> : project?.project_data?.Tags?.join(', ')}</b></Text>
                  <Text>Project Status: <b>{loadingDetails ? <Spin /> : project?.project_status}</b></Text>
                </Space>
              </Col>
              <Col span={21}>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <TextArea
                      className="w-full"
                      placeholder="Carton"
                      onChange={(e) => setQuery({ ...query, carton: e.target.value.replaceAll('\n', '\\') })}
                    />
                  </Col>
                  {filters.map(filter =>
                    <Col span={6}>
                      <Select
                        mode="multiple"
                        className="w-full"
                        showSearch
                        allowClear
                        options={dataByField[filter.label] || []}
                        placeholder={filter.label.charAt(0).toUpperCase() + filter.label.slice(1)}
                        notFoundContent={loadingDataByField ? <Spin size="small" /> : null}
                        onChange={(value) => setQuery({ ...query, [filter.label]: value })}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span={3} className="utils">
                <Button
                  type="primary"
                  disabled={loadingExport}
                  icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />}
                  onClick={() => exportExcel(token, setToken, query, setLoadingExport, setError, openNotification, 'carton', window.location.href.split('?')[1].split('=')[1])}
                >
                  Export
                </Button>
              </Col>
            </Row>
            <Badge status="success" text={`Qty: ${total}`} className="total" />
            <Table
              columns={columns}
              scroll={{ x: 'max-content' }}
              dataSource={cartons.map((d, idx) => {
                return {
                  key: idx,
                  ...d
                }
              })}
              onChange={handleChange}
              rowKey="key"
              pagination={{ total: total }}
            />
          </Spin>
        </main>
      </div>
    </Context.Provider >
  )
}

export default Carton