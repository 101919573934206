import { SERVER_API_URL } from "../constants"
import axios from "axios"

export const getCartonsAPI = (token, limit, offset, sortStr, query, project) => {
  let requestBody = {}
  for (const property in query) {
    if (property === 'carton') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: query[property]
        }
      }
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: (query[property]).join('\\')
        }
      }
    }
  }

  return axios.post(`${SERVER_API_URL}/carton?limit=${limit}&skip=${offset}${!!sortStr ? `&sort=${sortStr}` : ''}&project=${project}`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }
  })
}

export const getDataByFieldForCartonAPI = (token, project) => {
  return axios.get(SERVER_API_URL + "/carton/field?project=" + project, {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}