import { SERVER_API_URL } from "../constants"
import axios from "axios"

export const getDevicesAPI = (token, sortStr, query, moreQuery, columns) => {
    let requestBody = {columns: columns.map(cl => cl.key).join(',')}
    for (const property in query) {
        if (property === 'device' || property === 'imei') {
            if (query[property].length) {
                requestBody = {
                    ...requestBody,
                    [property]: query[property]
                }
            }
        }
        else {
            if (query[property].length) {
                requestBody = {
                    ...requestBody,
                    [property]: (query[property]).join('\\')
                }
            }
        }
    }

    return axios.post(`${SERVER_API_URL}/device?${!!moreQuery ? moreQuery.replaceAll('+', '%2B') : ""}${!!sortStr ? `&sort=${sortStr}` : ''}`, requestBody, {
        headers: {
            "Authorization": token,
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": SERVER_API_URL
        }
    })
}

export const getDataByFieldForDeviceAPI = (token, moreQuery) => {
    return axios.get(SERVER_API_URL + "/device/field" + (!!moreQuery ? "?" + moreQuery : ""), {
        headers: {
            "Authorization": token,
            "Access-Control-Allow-Origin": SERVER_API_URL
        },
    })
}