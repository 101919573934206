import { getRefreshTokenAPI, getPOsAPI, getPOAPI, getDetailsAPI, getVendorsAPI, exportExcelAPI, getDataByFieldForPOAPI, getStaticsAPI, getNotesAPI } from "../../services"

export const getPOs = async (setPOs, setLoading, setTotal, setError, setToken, openNotification, token, limit, offset, sortStr, query) => {
  try {
    setLoading(true)
    const { data } = await getPOsAPI(token, limit, offset, sortStr, query)
    setPOs(data.data)
    setTotal(data.total_count)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getPO = async (setPO, setLoading, setTotal, setError, setToken, openNotification, token, po, limit, offset, sortStr, query, columns) => {
  try {
    setLoading(true)
    let col = columns.filter(cl => cl.key !== "count")
    const { data } = await getPOAPI(token, po, limit, offset, sortStr, query, col)
    setPO(data.data)
    setTotal(data.total_count)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

// export const getPOTest = async (setPO, setLoading, setTotal, setError, setToken, openNotification, token, po, limit, offset, sortStr) => {
//   try {
//     setLoading(true)
//     const {data} = await getPOAPI(token, po, limit, offset, sortStr)
//     // setPO(data.data)
//     // setTotal(data.total_count)
//     setError("")
//     return <div>{data.total_count}</div>
//   } catch (error) {
//     console.error("FAILED :(", error)
//     setError(error.response)
//     openNotification('topRight')
//     if (error.response && error.response.status === 401) {
//       try {
//         setLoading(true)
//         if (!!localStorage.getItem('refresh_token')) {
//           let newToken = await getRefreshTokenAPI()
//           setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
//         } else {
//           localStorage.removeItem('token')
//           window.location.href = "/"
//         }
//       } catch (error) {
//         if (error.response && error.response.status === 401) {
//           localStorage.removeItem('token')
//           window.location.href = "/"
//         }
//       } finally {
//         setLoading(false)
//       }
//     }
//   } finally {
//     setLoading(false)
//   }
// }

export const showDetailsInfo = async (token, setShowDetails, imei, setTestData, setOdditData, setLoading, setError, setToken, openNotification) => {
  try {
    setLoading(true)
    const { data } = await getDetailsAPI(token, imei)
    setTestData(data.testData)
    setOdditData(data.odditData.map(d => {
      return {
        ...d,
        project_tags: ((d.project_data || {})["Tags"] || []).length > 0 ? ((d.project_data || {})["Tags"] || []).join(', ') : 'Empty'
      }
    }))
    setShowDetails(imei)
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getVendorsData = async (setDataByField, setLoadingDataByField, setLoading, setError, setToken, openNotification, token) => {
  try {
    setLoadingDataByField(true)
    const { data } = await getVendorsAPI(token)
    setDataByField({
      vendor: data.map(d => {
        return {
          label: d,
          value: d
        }
      })
    })
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const getDataByField = async (setDataByField, setLoadingDataByField, setError, openNotification, setLoading, token, setToken, filters, po) => {
  try {
    setLoadingDataByField(true)
    const { data } = await getDataByFieldForPOAPI(token, po)
    filters.map((field) => {
      setDataByField(prev => {
        return {
          ...prev,
          [field]: ((data || {})[field] || []).map((d, idx) => {
            return {
              label: d,
              value: d
            }
          })
        }
      })
    })
  } catch (error) {
    console.error("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingDataByField(false)
  }
}

export const exportExcel = async (token, setToken, query, setLoading, setError, openNotification, from, po, columns) => {
  try {
    setLoading(true)
    console.log('columns', columns)
    let col = columns.filter(cl => cl.key !== "count")
    // const {data, headers} = await exportExcelAPI(token, query)
    const response = await exportExcelAPI(token, query, from, po, col)
    let url = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    const disposition = response.headers.get('Content-Disposition');
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    if (filename.toLowerCase().startsWith("utf-8''"))
      filename = decodeURIComponent(filename.replace("utf-8''", ''));
    else
      filename = filename.replace(/['"]/g, '');
    link.setAttribute(
      'download',
      `${filename}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setError('')
  } catch (error) {
    console.log("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getStatics = async (setStatics, setLoadingStatics, setError, openNotification, setLoading, token, setToken, po) => {
  try {
    setLoadingStatics(true)
    const {data} = await getStaticsAPI(token, po)
    console.log('data', data)
    setStatics(data)
    setError('')
  } catch (error) {
    console.log("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingStatics(false)
  }
}

export const getNotes = async (openNotification, token, setToken, setNotes, setLoadingNotes, setLoading, setError, esn) => {
  try {
    setLoadingNotes(true)
    const { data } = await getNotesAPI(token, 'po', esn)
    setNotes((data || []))
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingNotes(false)
  }
}