import React, { useState, useCallback } from 'react'
import { Form, Input, Button, Spin, Alert, Space } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { loginAPI } from '../services'
import '../App.css'

const Login = ({ setToken }) => {
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const login = useCallback(async () => {
    try {
      setLoading(true)
      const response = await loginAPI(username, password)
      setToken(`${response.data.token_type} ${response.data.access_token}`)
      localStorage.setItem('refresh_token', response.data.refresh_token)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [password, username, setToken])

  return (
    <div className='login-wrapper'>
      <Spin spinning={loading} tip='Loading...' size='large'>
        <Space direction='vertical' className='w-full'>
          {error && <Alert message='Login Failed' type='error' />}
          <Form
            name='login'
            className='login-form'
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              name='username'
              rules={[{
                required: true,
                message: 'Please input your Username!'
              }]}
            >
              <Input
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Username' 
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[{
                required: true,
                message: 'Please input your Password!'
              }]}
            >
              <Input
                prefix={<LockOutlined className='site-form-item-icon' />}
                type='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item className='last-item'>
              <Button
                type='primary'
                className='login-form-button'
                onClick={login}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </div>
  )
}

export default Login