import { useState, useEffect, useRef } from 'react'
import { Tag, Space, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const Tags = ({ text, addTag, removeTag }) => {
  const [tags, setTags] = useState(text)
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
      console.log('inputRef', inputRef)
    }
  }, [inputVisible]);

  const handleClose = async (tag) => {
    console.log(tags)
    const newTags = tags?.split(', ').filter((t) => t !== tag);
    await removeTag(tag, setTags, newTags.join(', '))
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = async () => {
    if (inputValue && ((tags || "").split(', ').indexOf(inputValue) === -1)) {
      await addTag(inputValue, setTags, `${!!tags ? tags + ", ":''}${inputValue}`)
    }
    setInputVisible(false);
    setInputValue('');
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const tagPlusStyle = {
    background: 'white',
    borderStyle: 'dashed',
  };
  return <>
    <Space size={[0, 'small']} wrap>
      {!!tags ? tags?.split(', ').map(forMap):<></>}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag onClick={showInput} style={tagPlusStyle}>
          <PlusOutlined /> New Tag
        </Tag>
      )}
    </Space>
  </>
}

export default Tags