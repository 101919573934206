import { useState, useEffect, useMemo, createContext } from "react"
import { useLocation } from "react-router-dom";
import { Row, Col, Select, Input, Table, Badge, Spin, Button, notification, Card, Popover, Space, Typography, Tabs, Statistic } from "antd"
import { Link } from "react-router-dom";
import { DownloadOutlined, LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { getSalesOrders, handleFieldChange, exportExcel, getDataByField, getProductsForOrder, getOrderDesc, getNotes, changeStatus, changePrice } from "./utils"
import _ from 'lodash';
import { filters, filtersForOrder } from "./constants"
import ContentForPrice from "./ContentForPrice";
const { Text } = Typography;
const { TextArea } = Input
const Context = createContext({
  status: '500',
  errorMsg: 'Internal Server Error'
})

const SalesOrder = ({ token, setToken }) => {
  const [loading, setLoading] = useState(false)
  const [loadingNotes, setLoadingNotes] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [orderNum, setOrderNum] = useState(0)
  const [columnsForOrder, setColumnsForOrder] = useState([])
  const [showColumns, setShowColumns] = useState([])
  const [loadingDataByField, setLoadingDataByField] = useState(false)
  const [loadingDataByFieldForOrder, setLoadingDataByFieldForOrder] = useState(false)
  const [salesOrders, setSalesOrders] = useState([])
  const [products, setProdcuts] = useState([])
  const [product, setProdcut] = useState([])
  const [dataByField, setDataByField] = useState({})
  const [dataByFieldForOrder, setDataByFieldForOrder] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [sort, setSort] = useState()
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [query, setQuery] = useState({})
  const [totalForOrder, setTotalForOrder] = useState(0)
  const [queryForOrder, setQueryForOrder] = useState({})
  const [error, setError] = useState({})
  const [price, setPrice] = useState()
  const [status, setStatus] = useState()
  const [customer, setCustomer] = useState()
  const [orderDesc, setOrderDesc] = useState()
  const [api, contextHolder] = notification.useNotification()
  const [notes, setNotes] = useState([])
  const [open, setOpen] = useState('')
  const [statusLoading, setStatusLoading] = useState(false)
  const [priceLoading, setPriceLoading] = useState(false)
  const [selected, setSelected] = useState(false)

  let location = useLocation()

  const contentForStatus = <Space size="middle"><Input value={status} placeholder="status" onChange={(e) => setStatus(e.target.value)} /><Button onClick={() => changeStatus(openNotification, token, setToken, setLoading, setError, status, setOpen, salesOrders.filter(so => so.order_number === Object.keys(open)[0].split('-')[0])[0]?.order_id, setStatusLoading, offset, pageSize, query, sort, setSalesOrders, setTotal, setProdcut, window.location.href.split('/').slice(4).join('/'), "status")} icon={statusLoading ? <Spin size="small" /> : <CheckOutlined />} /></Space>
  const contentForCustomer = <Space size="middle"><Input value={customer} placeholder="Customer Name" onChange={(e) => setCustomer(e.target.value)} /><Button onClick={() => changeStatus(openNotification, token, setToken, setLoading, setError, customer, setOpen, salesOrders.filter(so => so.order_number === Object.keys(open)[0].split('-')[0])[0]?.order_id, setStatusLoading, offset, pageSize, query, sort, setSalesOrders, setTotal, setProdcut, window.location.href.split('/').slice(4).join('/'), "customer")} icon={statusLoading ? <Spin size="small" /> : <CheckOutlined />} /></Space>
  const contentForOrderDesc = <Space size="middle"><Input value={orderDesc} placeholder="Order Description" onChange={(e) => setOrderDesc(e.target.value)} /><Button onClick={() => changeStatus(openNotification, token, setToken, setLoading, setError, orderDesc, setOpen, salesOrders.filter(so => so.order_number === Object.keys(open)[0].split('-')[0])[0]?.order_id, setStatusLoading, offset, pageSize, query, sort, setSalesOrders, setTotal, setProdcut, window.location.href.split('/').slice(4).join('/'), "order_desc")} icon={statusLoading ? <Spin size="small" /> : <CheckOutlined />} /></Space>
  const columns = [
    {
      title: 'Order',
      dataIndex: 'order_number',
      key: 'order_number',
      sorter: {
        multiple: 1
      },
      render: (text, record) => <Link to={`/order/${text}`} onClick={() => setOrderNum(text)}>{text}</Link>,
    },
    {
      title: 'Customer',
      dataIndex: 'order_customer',
      key: 'order_customer',
      sorter: {
        multiple: 2
      },
      render: (text, record) => <Popover content={contentForCustomer} title="Customer" trigger="click" open={open[`${record.order_number}-customer`]} onOpenChange={(op) => { setOpen({ [`${record.order_number}-customer`]: op }); console.log(open); setCustomer(text) }}>
        <div className="w-full" style={{ height: '22px', border: '1px solid gray' }}>{text}</div>
      </Popover>,
    },
    {
      title: 'Description',
      dataIndex: 'order_description',
      key: 'order_description',
      sorter: {
        multiple: 3
      },
      render: (text, record) => <Popover content={contentForOrderDesc} title="Order Description" trigger="click" open={open[`${record.order_number}-orderDesc`]} onOpenChange={(op) => { setOpen({ [`${record.order_number}-orderDesc`]: op }); console.log(open); setOrderDesc(text) }}>
        <div className="w-full" style={{ height: '22px', border: '1px solid gray' }}>{text}</div>
      </Popover>,
    },
    {
      title: 'Date',
      dataIndex: 'order_created',
      key: 'order_created',
      sorter: {
        multiple: 4
      },
      render: (text) => (() => {
        let temp = new Date(text).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
        return `${temp[2]}-${temp[0]}-${temp[1]}`
      })()
    },
    {
      title: 'Status',
      dataIndex: 'order_status',
      key: 'order_status',
      sorter: {
        multiple: 4
      },
      render: (text, record) => <Popover content={contentForStatus} title="Status" trigger="click" open={open[`${record.order_number}-status`]} onOpenChange={(op) => { setOpen({ [`${record.order_number}-status`]: op }); console.log(open); setStatus(text) }}>
        <div className="w-full" style={{ height: '22px', border: '1px solid gray' }}>{text}</div>
      </Popover>,
    },
    {
      title: 'Qty',
      dataIndex: 'total_qty',
      key: 'total_qty',
      sorter: {
        multiple: 4
      }
    },
    {
      title: 'Total Value',
      dataIndex: 'total_value',
      key: 'total_value',
      sorter: {
        multiple: 4
      }
    },
  ]

  // const notes = [
  //   {
  //     added: '2022-12-01 07:43:17.171 +0900',
  //     note: 'JJ RMA: CN-00047',
  //     obj: '352810100551049'
  //   },
  //   {
  //     added: '2022-12-03 18:43:17.171 +0900',
  //     note: 'JJ RMA: CN-00048',
  //     obj: '354775100413763'
  //   },
  //   {
  //     added: '2022-12-03 05:43:17.171 +0900',
  //     note: 'JJ RMA: CN-00049',
  //     obj: '354775100413763'
  //   },
  // ]

  useEffect(() => {
    if (selected)
      changePrice(openNotification, token, setToken, setLoading, setError, setOpen, setPriceLoading, columnsForOrder, open, price, selected, queryForOrder, window.location.href.split('/').slice(4).join('/'), setTotalForOrder, setProdcuts, setPrice)
  }, [selected])

  useEffect(() => {
    let col = []
    if (products.length > 0) {
      col = Object.keys(products[0]).filter(d => !['rowcount'].includes(d)).map((d, idx) => {
        if (d === "product") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sortDirections: ['descend', 'ascend'],
            sorter: {
              multiple: 1,
              compare: (a, b) => { if (a[d] > b[d]) return -1; else return 1 }
            },
            render: (text, record) => <Link to={`/imei/${text}`}>{text}</Link>,
          }
        } else if (d === "product_price") {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sortDirections: ['descend', 'ascend'],
            sorter: {
              multiple: 1,
              compare: (a, b) => { if (a[d] > b[d]) return -1; else return 1 }
            },
            render: (text, record) => <Popover content={
              <Space size="middle">
                <Input placeholder="price" onChange={(e) => setPrice(e.target.value)} />
                <Button onClick={() => { setSelected(record); }} icon={priceLoading ? <Spin size="small" /> : <CheckOutlined />} />
              </Space>}
              title="Price" trigger="click">
              <div className="w-full" style={{ height: '22px', border: '1px solid gray' }}>{text}</div>
            </Popover>,
          }
        } else {
          return {
            title: d,
            dataIndex: d,
            key: d,
            sortDirections: ['descend', 'ascend'],
            sorter: {
              multiple: 1,
              compare: (a, b) => { if (a[d] > b[d]) return -1; else return 1 }
            },
          }
        }
      })
      if (columnsForOrder.filter(cl => cl.key !== "count").length === 0) {
        setShowColumns(col)
        setColumnsForOrder(col)
      }
    }
  }, [products, price, open, priceLoading])

  const openNotification = (placement) => {
    api.error({
      message: <Context.Consumer>{({ status }) => status}</Context.Consumer>,
      description: <Context.Consumer>{({ errorMsg }) => `${errorMsg}!`}</Context.Consumer>,
      placement
    })
  }

  const contextValue = useMemo(() => !!error && ({
    status: error.status,
    errorMsg: error.statusText
  }), [error])

  const handleSearch = _.debounce((e) => {
    setQuery({ ...query, order_number: e.target.value.replaceAll('\n', '\\') })
  }, 500);

  const handleSearchForProduct = _.debounce((e) => {
    setQueryForOrder({ ...queryForOrder, product: e.target.value.replaceAll('\n', ' ').replaceAll(' ', '\\') })
  }, 500);

  const handleChange = (pagination, filters, sorter) => {
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    setPageSize(limit)
    setOffset(offset)
    let sortStr = ""
    if (Array.isArray(sorter)) {
      sorter.map((s, idx) => {
        if (idx !== 0)
          sortStr += " "
        if (!!s.order && s.order === "ascend")
          sortStr += "+" + s.field
        else
          sortStr += "-" + s.field
        return s
      })
    } else {
      if (!!sorter.order) {
        if (sorter.order === "ascend")
          sortStr += "+" + sorter.field
        else
          sortStr += "-" + sorter.field
      }
    }
    setSort(sortStr)
  };

  useEffect(() => {
    if (!!window.location.href.split('/')[4]) {
      getDataByField(setError, openNotification, setDataByFieldForOrder, setLoading, setLoadingDataByFieldForOrder, token, setToken, filtersForOrder)
      getOrderDesc(openNotification, token, setToken, setProdcut, setLoading, setError, window.location.href.split('/').slice(4).join('/'))
      getNotes(openNotification, token, setToken, setNotes, setLoadingNotes, setLoading, setError, window.location.href.split('/').slice(4).join('/'))
      setOrderNum(window.location.href.split('/')[4])
    } else {
      setOrderNum(window.location.href.split('/')[4])
      handleFieldChange(setError, openNotification, setDataByField, setLoading, setLoadingDataByField, token, setToken, filters)
    }
  }, [token, location])

  useEffect(() => {
    if (!!window.location.href.split('/')[4]) {
      getProductsForOrder(openNotification, token, setToken, setProdcuts, setLoading, setTotalForOrder, setError, queryForOrder, window.location.href.split('/').slice(4).join('/'), columnsForOrder)
    } else {
      getSalesOrders(openNotification, token, setToken, setSalesOrders, setLoading, setTotal, setError, offset, pageSize, query, sort)
    }
  }, [query, token, offset, sort, queryForOrder, orderNum, columnsForOrder, pageSize])

  const itemsForOrder = [
    {
      key: '1',
      label: 'Products',
      children: <Row gutter={[8, 8]}>
        <Col span={24}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h2>Order #{product?.order_number} from {product?.order_customer ? product?.order_customer : 'Undefined'}</h2>
              <Space>
                <Text>Order Created: {(() => {
                  if (!!product?.order_created) {
                    let temp = new Date(product?.order_created).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
                    return `${temp[2]}/${temp[0]}/${temp[1]}`
                  } else {
                    return ''
                  }
                })()}</Text>
                <Popover content={contentForStatus} title="Status" trigger="click" open={open[product?.order_number]} onOpenChange={(open) => { setOpen({ [product?.order_number]: open }); setStatus(product?.order_status) }}>
                  <div className="w-full" style={{ display: 'flex' }}>
                    <Text>Order Status:&nbsp;</Text>
                    <div style={{ height: '22px', border: '1px solid gray', minWidth: '100px' }}>{product?.order_status}</div>
                  </div>
                </Popover>
              </Space>
            </div>
          </div>
        </Col>
        <Col span={15}>
          <TextArea
            className="w-full"
            placeholder="Product"
            onChange={handleSearchForProduct}
          />
        </Col>
        <Col span={6}>
          <Row gutter={[16, 16]}>
            {
              filtersForOrder.map(filter =>
                <Col span={24}>
                  <Select
                    key={filter}
                    mode="multiple"
                    className="w-full"
                    showSearch
                    allowClear
                    options={dataByFieldForOrder[filter] || []}
                    placeholder={filter.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')}
                    notFoundContent={loadingDataByFieldForOrder ? <Spin size="small" /> : null}
                    onChange={(value) => setQueryForOrder({ ...queryForOrder, [filter]: value })}
                  />
                </Col>
              )
            }
          </Row>
        </Col>
        <Col span={3} className="utils">
          <Button
            type="primary"
            disabled={loadingExport}
            icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />}
            onClick={() => exportExcel(token, setToken, queryForOrder, setLoadingExport, setError, openNotification, 'sales_orders', window.location.href.split('/').slice(4).join('/'), columnsForOrder.filter(cl => cl.key !== "count"))}>
            Export
          </Button>
        </Col>
        <Col span={24}>
          <Select
            mode="multiple"
            className="w-full"
            allowClear
            options={showColumns.map(d => {
              return {
                label: d.key,
                value: d.key
              }
            })}
            placeholder="Columns"
            notFoundContent={showColumns ? <Spin size="small" /> : null}
            onChange={(value) => {
              setColumnsForOrder([...(showColumns.filter(d => value.includes(d.key))), ...((showColumns.filter(d => value.includes(d.key))).map(cl => cl.key).includes('device') ? [] : [{
                title: 'count',
                dataIndex: 'count',
                key: 'count',
                sorter: {
                  multiple: 1000,
                }
              }])])
            }}
          />
        </Col>
        <Col span={24}>
          <Badge status="success" text={`Qty: ${totalForOrder}`} className="total" />
          <Table
            columns={columnsForOrder}
            dataSource={products}
            rowKey={(record) => record.product}
            scroll={{ x: 'max-content' }}
            pagination={{ total: totalForOrder }}
          />
        </Col>
      </Row>
    },
    {
      key: '2',
      label: 'Analytics',
      children: <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Analytics 1"
              value={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Analytics 2"
              value={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Analytics 3"
              value={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Analytics 4"
              value={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Analytics 5"
              value={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
      </Row>
    },
    {
      key: '3',
      label: <Badge count={loadingNotes ? <Spin size="small"></Spin> : notes.length} offset={[15, 0]}><span>Notes</span></Badge>,
      children: <Spin spinning={loadingNotes} tip="Loading..." size="large">
        <Space direction="vertical">
          <Card title="Notes" style={{ width: '300px' }}>
            <Space size="middle" direction="vertical">
              {(() => {
                const groups = notes.map(note => {
                  let temp = new Date(note.added).toLocaleString('en-US').split(', ')
                  return {
                    ...note,
                    date: temp[0],
                    time: temp[1]
                  }
                }).reduce((group, product) => {
                  const { date } = product;
                  group[date] = group[date] ?? [];
                  group[date].push(product);
                  return group;
                }, {})
                return Object.keys(groups).map((date, idx) =>
                  <Space size="small" direction="vertical">
                    <Text><b>{date}</b></Text>
                    {
                      groups[date].map(data =>
                        <Space size="middle" style={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Text style={{ whiteSpace: 'nowrap' }}>{data.time}</Text>
                          <Text>{data.note}</Text>
                        </Space>
                      )
                    }
                  </Space>)
              })()}
            </Space>
          </Card>
        </Space>
      </Spin>
    },
  ]

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="home-body">
        <main className="container w-full">
          <Spin spinning={loading} tip="Loading..." size="large">
            {!orderNum ? <Row gutter={[8, 8]}>
              <Col span={15}>
                <Input
                  className="w-full"
                  placeholder="Order #"
                  onChange={handleSearch}
                />
              </Col>
              <Col span={6}>
                <Row gutter={[16, 16]}>
                  {
                    filters.map(filter =>
                      <Col span={24}>
                        <Select
                          key={filter}
                          mode="multiple"
                          className="w-full"
                          showSearch
                          allowClear
                          options={dataByField[filter] || []}
                          placeholder={filter.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')}
                          notFoundContent={loadingDataByField ? <Spin size="small" /> : null}
                          onChange={(value) => setQuery({ ...query, [filter]: value })}
                        />
                      </Col>
                    )
                  }
                </Row>
              </Col>
              <Col span={3} className="utils">
                <Button
                  type="primary"
                  disabled={loadingExport}
                  icon={loadingExport ? <LoadingOutlined /> : <DownloadOutlined />}
                  onClick={() => exportExcel(token, setToken, query, setLoadingExport, setError, openNotification, 'sales_orders')}>
                  Export
                </Button>
              </Col>
              <Col span={24}>
                <Badge status="success" text={`Qty: ${total}`} className="total" />
                <Table
                  columns={columns}
                  dataSource={salesOrders}
                  rowKey={(record) => record.order_number}
                  onChange={handleChange}
                  scroll={{ x: 'max-content' }}
                  pagination={{ total: total }}
                />
              </Col>
            </Row> :
              <Tabs defaultActiveKey="1" items={itemsForOrder} onChange={() => console.log('tab for order changed')} />
            }
          </Spin>
        </main>
      </div >
    </Context.Provider >
  )
}

export default SalesOrder