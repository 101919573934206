import { SERVER_API_URL } from "../constants"
import axios from "axios"

export const getPOsAPI = (token, limit, offset, sortStr, query) => {
  let requestBody = {}
  for (const property in query) {
    if (property === 'po_number') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: query[property]
        }
      }  
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: (query[property]).join('\\')
        }
      }  
    }
  }
  return axios.post(`${SERVER_API_URL}/po?limit=${limit}&skip=${offset}${!!sortStr ? `&sort=${sortStr}`: ''}`, requestBody, {
    headers: {
      "Authorization": token,
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": SERVER_API_URL
    }})
}

export const getPOAPI = (token, po, limit, offset, sortStr, query, columns) => {
  let requestBody = {columns: columns.map(cl => cl.key).join(',')}
  for (const property in query) {
    if (property === 'imei' || property === 'po_number') {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: query[property]
        }
      }  
    }
    else {
      if (query[property].length) {
        requestBody = {
          ...requestBody,
          [property]: (query[property]).join('\\')
        }
      }  
    }
  }
  return axios.post(`${SERVER_API_URL}/po/${po}?limit=${limit}&skip=${offset}${!!sortStr ? `&sort=${sortStr}`: ''}`, requestBody, {
    headers: {
      "accept": "application/json",
      "Authorization": token,
      "Content-Type": "application/x-www-form-urlencoded",
    }})
}

export const getDetailsAPI = (token, imei) => {
  return axios.get(`${SERVER_API_URL}/po/device?imei=${imei}`, {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    }})
}

export const getVendorsAPI = (token) => {
  return axios.get(`${SERVER_API_URL}/po/vendors`, {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    }})
}

export const getDataByFieldForPOAPI = (token, po) => {
  return axios.get(SERVER_API_URL + "/po/field?po="+po, {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}

export const getStaticsAPI = (token, po) => {
  return axios.get(SERVER_API_URL + "/po/"+po+"/financial", {
    headers: {
      "Authorization": token,
      "Access-Control-Allow-Origin": SERVER_API_URL
    },
  })
}