import { getDetailsAPI, getRefreshTokenAPI, getNotesAPI, exportExcelAPI } from "../../services"

export const showDetailsInfo = async (token, imei, setTestData, setOdditData, setVendorData, setSalesOrders, setToken, setError, setLoading, openNotification) => {
  try {
    setLoading(true)
    const { data } = await getDetailsAPI(token, imei)
    setTestData(data.testData.map(d => {
      return {
        ...d,
        id: d.software + d.sw_id,
        damages: d.faults.length > 0 ? d.faults.join(', ') : 'Empty',
        grade: d.grade["Mapped Grade"],
        // tested: `${new Date(d.tested).toLocaleString('en-US', { timeZone: 'CST' }).getFullYear()}-${new Date(d.tested).toLocaleString('en-US', { timeZone: 'CST' }).getMonth() + 1}-${new Date(d.tested).toLocaleString('en-US', { timeZone: 'CST'}).getDay()}`
        tested: (() => {
          let temp = new Date(d.tested).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
          return `${temp[2]}-${temp[0]}-${temp[1]}`
        })()
      }
    }))
    setSalesOrders(data.salesOrderData.map(d => {
      return {
        ...d,
        // tested: `${new Date(d.tested).toLocaleString('en-US', { timeZone: 'CST' }).getFullYear()}-${new Date(d.tested).toLocaleString('en-US', { timeZone: 'CST' }).getMonth() + 1}-${new Date(d.tested).toLocaleString('en-US', { timeZone: 'CST'}).getDay()}`
        order_created: (() => {
          let temp = new Date(d.order_created).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
          return `${temp[2]}-${temp[0]}-${temp[1]}`
        })()
      }
    }))
    setOdditData(data.odditData.map(d => {
      return {
        ...d,
        project_tags: ((d.project_data || {})["Tags"] || []).length > 0 ? ((d.project_data || {})["Tags"] || []).join(', ') : 'Empty',
        device_modified: (() => {
          if (!!d.device_modified) {
            let temp = new Date(d.device_modified).toLocaleString('en-US', { year: "numeric", month: "numeric", day: "numeric" }).split('/')
            return `${temp[2]}-${temp[0]}-${temp[1]}`
          } else {
            return ''
          }
        })()
      }
    }))
    setVendorData((data.vendorData || []))
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({status: '500', statusText: 'Internal Server Error'})
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoading(false)
  }
}

export const getNotes = async (openNotification, token, setToken, setNotes, setLoadingNotes, setLoading, setError, esn) => {
  try {
    setLoadingNotes(true)
    const { data } = await getNotesAPI(token, 'po', esn)
    setNotes((data || []))
    setError("")
  } catch (error) {
    console.error("FAILED :(", error)
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError(error.response)
          openNotification('topRight')
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingNotes(false)
  }
}

export const exportExcel = async (openNotification, token, setToken, setLoadingExport, setLoading, setError, esn) => {
  try {
    setLoadingExport(true)
    const response = await exportExcelAPI(token, null, 'test', esn)
    let url = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    const disposition = response.headers.get('Content-Disposition');
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    if (filename.toLowerCase().startsWith("utf-8''"))
      filename = decodeURIComponent(filename.replace("utf-8''", ''));
    else
      filename = filename.replace(/['"]/g, '');
    link.setAttribute(
      'download',
      `${filename}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setError('')
  } catch (error) {
    console.log("FAILED :(", error)
    setError(error.response)
    openNotification('topRight')
    if (error.response && error.response.status === 401) {
      try {
        setLoading(true)
        if (!!localStorage.getItem('refresh_token')) {
          let newToken = await getRefreshTokenAPI()
          setToken(`${newToken.data.token_type} ${newToken.data.access_token}`)
        } else {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          window.location.href = "/"
        }
      } finally {
        setLoading(false)
      }
    } else if (!error.response.statusText) {
      setError({ status: '500', statusText: 'Internal Server Error' })
      openNotification('topRight')
    } else {
      setError(error.response)
      openNotification('topRight')
    }
  } finally {
    setLoadingExport(false)
  }
}